import { configureStore } from "@reduxjs/toolkit";
import routeSlice from "./routeSlice";
import transportSlice from "./transportSlice";

export const store = configureStore({
  reducer: {
    routeSlice,
    transportSlice
  },
});
