import React, { useState, useEffect } from "react";
import st from "./Weather.module.css";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import API from "../../api";
import Loader from "../Loader/Loader";
import meteoLogo from "../../assets/meteo_Logo.png";
import WeatherIcon from "../../assets/weather";
import { FaLocationArrow } from "react-icons/fa";
import dayjs from "dayjs";
import { addWeather, updateWeather } from "../../redux/routeSlice";
import dayWeatherAverages from "../../helpers/weatherAverages";
import {
  formatCityNameAPI,
  formatCityNameLink,
} from "../../helpers/formatCityName";
import { Link } from "react-router-dom";

const Weather = () => {
  const [weather, setWeather] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedRouteInfo } = useSelector((state) => state.routeSlice);
  const allWeather = useSelector((state) => state.routeSlice.weatherForecasts);

  useEffect(() => {
    // NOTE: used Timeout to prevent accidental swiping over routes
    let fetchWeather;

    if (selectedRouteInfo) {
      const weatherCheck = allWeather.find(
        (weather) => weather.routeId === selectedRouteInfo.properties._id
      );

      if (weatherCheck?.updatedAt > dayjs().subtract(1, "hour").format()) {
        setWeather(weatherCheck.weather);
      } else {
        setIsLoading(true);
        fetchWeather = setTimeout(() => {
          API.getWeather(
            (fetchedWeather) => {
              let filteredWeather = fetchedWeather.forecastTimestamps.filter(
                (dayMonth) =>
                  dayjs(dayMonth.forecastTimeUtc).isAfter() &&
                  dayjs(dayMonth.forecastTimeUtc).hour() > 8 &&
                  dayjs(dayMonth.forecastTimeUtc).hour() <= 22
              );

              let filteredWeekdays = Array.from(
                new Set(
                  filteredWeather.map((day) =>
                    dayjs(day.forecastTimeUtc).date()
                  )
                )
              );

              const forecasts = filteredWeekdays.map((day) =>
                dayWeatherAverages(day, filteredWeather)
              );

              setWeather(forecasts);

              if (!weatherCheck) {
                dispatch(
                  addWeather({
                    routeId: selectedRouteInfo.properties._id,
                    forecast: forecasts,
                  })
                );
              } else {
                dispatch(
                  updateWeather({
                    routeId: selectedRouteInfo.properties._id,
                    forecast: forecasts,
                  })
                );
              }

              setIsLoading(false);
            },
            (error) => console.error("Weather error:", error),
            formatCityNameAPI(selectedRouteInfo?.properties.location)
          );
        }, "500");
      }
    } else {
      setWeather(undefined);
      setIsLoading(false);
    }

    return () => clearTimeout(fetchWeather);
  }, [selectedRouteInfo, allWeather]);

  return (
    <section className={st.container}>
      <h5 className={st.h5}>
        {t("weather_title")}
        <span>{selectedRouteInfo?.properties.location}</span>
      </h5>

      <div className={st.content}>
        {weather ? (
          <Link
            to={
              selectedRouteInfo &&
              `${t("weather_link")}${t(
                "weather_link_forecast"
              )}${formatCityNameLink(selectedRouteInfo.properties.location)}`
            }
            target="_blank"
            rel="noreferrer"
            className={[st.content, st.link].join(" ")}
          >
            <ul className={st.contentRow}>
              {weather.map((day) => (
                <li className={st.contentItem} key={day.date}>
                  <div className={st.weatherCol}>
                    <span className={st.day}>
                      {dayjs().isSame(day.date, "day")
                        ? t("weather_today")
                        : `${dayjs(day.date).format("ddd")} ${dayjs(
                            day.date
                          ).format("D")}`}
                    </span>
                    <span className={st.degrees}>{day.maxTemp}&deg;C</span>
                    <img
                      title={t(`weather.${day.avgCondition}`)}
                      src={WeatherIcon(day.avgCondition)}
                      alt="wather icon"
                      className={st.weatheIcon}
                    />
                    <div className={st.weatherCol}>
                      <span className={st.wind}>{day.avgWind}m/s</span>
                      <FaLocationArrow
                        size="10px"
                        style={{
                          margin: "auto",
                          transform: `rotate(${day.avgWindDirection + 135}deg)`,
                        }}
                      />
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </Link>
        ) : isLoading ? (
          <Loader />
        ) : (
          <Link
            to={t("weather_link")}
            target="_blank"
            rel="noreferrer"
            className={st.link}
          >
            <img src={meteoLogo} alt="lhmt logo" className={st.weatherLogo} />
            <span className={st.noDataContent}>{t("weather_provider")}</span>
          </Link>
        )}
      </div>
    </section>
  );
};

export default Weather;
