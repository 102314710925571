import st from "./NoRoute.module.css";
import { useTranslation } from "react-i18next";
import { FaBicycle } from "react-icons/fa";
import Seo from "../components/Seo/Seo";

const About = () => {
  const { t } = useTranslation();

  return (
    <>
      <Seo title={t("noRoute_seoTitle")} />
      <main className={st.container}>
        <h3 className={st.h3}>{t("noRoute_title")}</h3>
        <div className={st.sign}>
          <FaBicycle />
        </div>
      </main>
    </>
  );
};

export default About;
