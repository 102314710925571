import React from "react";
import "./App.css";
import "./config/variables.css";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import ScrollToTop from "./config/scrollToTop";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
// import Join from "./pages/Join";
import Contact from "./pages/Contact";
import Privacy from "./pages/Privacy";
import Contribute from "./pages/Contribute";
import NoRoute from "./pages/NoRoute";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import "dayjs/locale/lt";
import RoutesList from "./components/RoutesList/RoutesList";
import RouteSingle from "./components/Route/Route";
import ItineraryLayout from "./pages/ItineraryLayout";
import { updateLocaleOption, updateLocaleOptions } from "primereact/api";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import CookieBanner from "./components/CookieBanner/CookieBanner";

// NOTE: primereact calendar locale update
updateLocaleOption("firstDayOfWeek", 1, "en");
updateLocaleOptions(
  {
    firstDayOfWeek: 1,
    dayNames: [
      "Sekmadienis",
      "Pirmadienis",
      "Antradienis",
      "Trečiadienis",
      "Ketvirtadienis",
      "Penktadienis",
      "Šeštadienis",
    ],
    dayNamesShort: ["Sek", "Pir", "Ant", "Tre", "Ket", "Pen", "Šeš"],
    dayNamesMin: ["S", "P", "A", "T", "K", "Pn", "Š"],
    monthNames: [
      "Sausis",
      "Vasaris",
      "Kovas",
      "Balandis",
      "Gegužė",
      "Birželis",
      "Liepa",
      "Rugpjūtis",
      "Rugsėjis",
      "Spalis",
      "Lapkritis",
      "Gruodis",
    ],
    monthNamesShort: [
      "Sau",
      "Vas",
      "Kov",
      "Bal",
      "Geg",
      "Bir",
      "Lie",
      "Rgp",
      "Rgs",
      "Spa",
      "Lap",
      "Gru",
    ],
    today: "Šiandien",
  },
  "lt"
);

const App = () => {
  const { i18n } = useTranslation();
  dayjs.locale(i18n.language);

  return (
    <Router>
      <ScrollToTop />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about" element={<About />} /> 
        <Route path="routes" element={<ItineraryLayout />}>
          <Route path="" element={<RoutesList />} />
          <Route path=":id" element={<RouteSingle />} />
        </Route>
        <Route path="contact" element={<Contact />} />
        {/* <Route path="join" element={<Join />} /> */}
        <Route path="contribute" element={<Contribute />} />
        <Route path="privacy" element={<Privacy />} />
        <Route path="*" element={<NoRoute />} />
      </Routes>
      <Footer />
      <CookieBanner />
    </Router>
  );
};

export default App;
