export const TRAIN = "TRAIN";
export const BUS = "BUS";
export const FERRY = "FERRY";
export const PARKING = "PARKING";

export const routeDifficulty = [
    "var(--color-secondary)",
    "var(--color-difficulty-1)",
    "var(--color-difficulty-2)",
    "var(--color-black)"
  ];
