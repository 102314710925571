import React, { useState } from "react";
import st from "./Legend.module.css";
import { useTranslation } from "react-i18next";
import { TbArrowWaveRightUp, TbRollerSkating, TbShip } from "react-icons/tb";
import { IoBus } from "react-icons/io5";
import { GiCrossroad, GiMountainRoad, GiHorizonRoad } from "react-icons/gi";
import { FaTrain, FaFileDownload } from "react-icons/fa";
import { FaRoad } from "react-icons/fa6";
import { GrCircleQuestion } from "react-icons/gr";
import { Dialog } from "primereact/dialog";

const Legend = () => {
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <button
        className={st.legendButton}
        onClick={() => setIsDialogVisible(true)}
      >
        <GrCircleQuestion
          color="var(--color-white)"
          title={t("legend_title")}
        />
      </button>
      <Dialog
        header={t("legend_title")}
        visible={isDialogVisible}
        onHide={() => setIsDialogVisible(false)}
        draggable={false}
        className={st.dialog}
      >
        <article>
          <ul className={st.legend}>
            <li>
              <span className={st.distance}>
                <span>56</span>
                <small>km</small>
              </span>
              <span>{t("legend_length")}</span>
            </li>
            <li>
              <span className={st.icon}>
                <FaRoad size={35} />
                <small>3km</small>
              </span>
              <span>{t("legend_asphaltRoad")}</span>
            </li>
            <li>
              <span className={st.icon}>
                <GiHorizonRoad size={35} />
                <small>3km</small>
              </span>
              <span>{t("legend_gravelRoad")}</span>
            </li>
            <li>
              <span className={st.icon}>
                <GiMountainRoad size={35} />
                <small>3km</small>
              </span>
              <span>{t("legend_offRoad")}</span>
            </li>
            <li>
              <span className={st.icon}>
                <GiCrossroad size={35} />
                <small>3km</small>
              </span>
              <span>{t("legend_bikePath")}</span>
            </li>
            <li>
              <span className={st.icon}>
                <TbArrowWaveRightUp
                  size={35}
                  color="var(--color-difficulty-1)"
                />
              </span>
              <span>
                {t("legend_difficulty")}{" "}
                <ul>
                  <li
                    style={{
                      color: "var(--color-secondary)",
                      listStyleType: "disc",
                    }}
                  >
                    {t("legend_difficulty_easy")}
                  </li>
                  <li
                    style={{
                      color: "var(--color-difficulty-1)",
                      listStyleType: "disc",
                    }}
                  >
                    {t("legend_difficulty_average")}
                  </li>
                  <li
                    style={{
                      color: "var(--color-difficulty-2)",
                      listStyleType: "disc",
                    }}
                  >
                    {t("legend_difficulty_hard")}
                  </li>
                  <li
                    style={{
                      color: "var(--color-black)",
                      listStyleType: "disc",
                    }}
                  >
                    {t("legend_difficulty_mtb")}
                  </li>
                </ul>
              </span>
            </li>
            <li>
              <span className={st.icon}>
                <TbRollerSkating size={35} color="var(--color-secondary)" />
              </span>
              <span>{t("legend_rollerblades")}</span>
            </li>
            <li>
              <span className={st.icon}>
                <FaTrain size={35} color="var(--color-secondary)" />
              </span>
              <span>{t("legend_trainStops")}</span>
            </li>
            <li>
              <span className={st.icon}>
                <IoBus size={35} color="var(--color-secondary)" />
              </span>
              <span>{t("legend_busStops")}</span>
            </li>
            <li>
              <span className={st.icon}>
                <TbShip size={35} color="var(--color-secondary)" />
              </span>
              <span>{t("legend_ferries")}</span>
            </li>
            <li>
              <span className={st.icon}>
                <FaFileDownload size={35} color="var(--color-secondary)" />
              </span>
              <span>{t("legend_download")}</span>
            </li>
          </ul>
        </article>
      </Dialog>
    </>
  );
};

export default Legend;
