import clear from "./clear.svg";
import cloudy1Sun from "./cloudy-1-sun.svg";
import cloudy2Sun from "./cloudy-2-sun.svg";
import cloudy from "./cloudy.svg";
import fog from "./fog.svg";
import fogSun from "./fog-sun.svg";
import freezingRain from "./freezing-rain.svg";
import hail from "./hail.svg";
import rain1 from "./rain-1.svg";
import rain1Sun from "./rain-1-sun.svg";
import rain2 from "./rain-2.svg";
import rain2Sun from "./rain-2-sun.svg";
import rain3 from "./rain-3.svg";
import rain3Sun from "./rain-3-sun.svg";
import sleetSun from "./sleet-sun.svg";
import sleet from "./sleet.svg";
import snow1 from "./snow-1.svg";
import snow1Sun from "./snow-1-sun.svg";
import snow2 from "./snow-2.svg";
import snow2Sun from "./snow-2-sun.svg";
import snow3 from "./snow-3.svg";
import snow3Sun from "./snow-3-sun.svg";
import thunder from "./thunder.svg";
import thunderstormsRain1 from "./thunderstorms-rain-1.svg";
import thunderstormsRain1sun from "./thunderstorms-rain-1-sun.svg";
import thunderstormsRain2 from "./thunderstorms-rain-2.svg";

const WeatherIcon = (weatherCondition) => {
  switch (weatherCondition) {
    case "clear":
      return clear;
    case "cloudy-1-sun":
      return cloudy1Sun;
    case "cloudy-2-sun":
      return cloudy2Sun;
    case "cloudy":
      return cloudy;
    case "fog":
      return fog;
    case "fog-sun":
      return fogSun;
    case "freezing-rain":
      return freezingRain;
    case "hail":
      return hail;
    case "rain-1":
      return rain1;
    case "rain-1-sun":
      return rain1Sun;
    case "rain-2":
      return rain2;
    case "rain-2-sun":
      return rain2Sun;
    case "rain-3":
      return rain3;
    case "rain-3-sun":
      return rain3Sun;
    case "sleet":
      return sleet;
    case "sleet-sun":
      return sleetSun;
    case "snow-1":
      return snow1;
    case "snow-1-sun":
      return snow1Sun;
    case "snow-2":
      return snow2;
    case "snow-2-sun":
      return snow2Sun;
    case "snow-3":
      return snow3;
    case "snow-3-sun":
      return snow3Sun;
    case "thunder":
      return thunder;
    case "thunderstorms-rain-1":
      return thunderstormsRain1;
    case "thunderstorms-rain-1-sun":
      return thunderstormsRain1sun;
    case "thunderstorms-rain-2":
      return thunderstormsRain2;

    default:
      return cloudy;
  }
};

export default WeatherIcon;
