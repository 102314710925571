import React from "react";
import st from "./Privacy.module.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Seo from "../components/Seo/Seo";

const Privacy = () => {
  const { t } = useTranslation();

  return (
    <>
      <Seo
        title={t("privacy_seoTitle")}
        description={t("privacy_seoDescription")}
      />
      <main className={st.container}>
        <h1 className={st.h1}>{t("privacy_title")}</h1>
        <article className={st.privacy}>
          <h3>{t("privacy_dataCollection.title")}</h3>
          <ul className={st.content}>
            <li>
              <strong>{t("privacy_dataCollection.ga.title")}</strong>
              {t("privacy_dataCollection.ga.content")}
            </li>
            <li>
              <strong>{t("privacy_dataCollection.contacts.title")}</strong>
              {t("privacy_dataCollection.contacts.content")}
            </li>
          </ul>

          <h3>{t("privacy_cookies.title")}</h3>
          <ul className={st.content}>
            <li>
              <strong>{t("privacy_cookies.policy.title")}</strong>
              {t("privacy_cookies.policy.content")}
            </li>
            <li>
              <strong>{t("privacy_cookies.gaCookies.title")}</strong>
              {t("privacy_cookies.gaCookies.content")}
            </li>
          </ul>

          <h3>{t("privacy_consent.title")}</h3>
          <ul className={st.content}>
            <li>
              <strong>{t("privacy_consent.policy.title")}</strong>
              {t("privacy_consent.policy.content")}
            </li>
            <li>
              <strong>{t("privacy_consent.cookiesManagment.title")}</strong>
              {t("privacy_consent.cookiesManagment.content")}
            </li>
          </ul>

          <h3>{t("privacy_thirdParty.title")}</h3>
          <ul className={st.content}>
            <li>
              <strong>{t("privacy_thirdParty.banners.title")}</strong>
              {t("privacy_thirdParty.banners.content")}
            </li>
          </ul>

          <h3>{t("privacy_security.title")}</h3>
          <ul className={st.content}>
            <li>
              <strong>{t("privacy_security.measures.title")}</strong>
              {t("privacy_security.measures.content")}
            </li>
            <li>
              <strong>{t("privacy_security.retention.title")}</strong>
              {t("privacy_security.retention.content")}
            </li>
          </ul>

          <h3>{t("privacy_contact.title")}</h3>
          <ul className={st.content}>
            <li>
              <strong>{t("privacy_contact.info.title")}</strong>
              {t("privacy_contact.info.content")}
              <Link to="/contact">{t("header_contact")}</Link>.
            </li>
          </ul>

          <h3>{t("privacy_updates.title")}</h3>
          <ul className={st.content}>
            <li>
              <strong>{t("privacy_updates.info.title")}</strong>
              {t("privacy_updates.info.content")}
            </li>
          </ul>
        </article>
      </main>
    </>
  );
};

export default Privacy;
