import * as errorCodes from "../config/errorCodes";

export const validateName = (name) => {
  if (!name) {
    throw errorCodes.NAME_IS_REQUIRED;
  }
  if (name.length < 3) {
    throw errorCodes.NAME_IS_TOO_SHORT;
  }
  if (name.length > 40) {
    throw errorCodes.NAME_IS_TOO_LONG;
  }
};

export const validateEmail = (userEmail) => {
  const emailRegex =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!userEmail) {
    throw errorCodes.EMAIL_IS_REQUIRED;
  } else if (!emailRegex.test(userEmail)) {
    throw errorCodes.EMAIL_INCORRECT;
  } else if (userEmail.length > 40) {
    throw errorCodes.EMAIL_TOO_LONG;
  }
};

export const validateMessage = (message) => {
  if (!message) {
    throw errorCodes.MESSAGE_IS_REQUIRED;
  }
  if (message.length < 10) {
    throw errorCodes.MESSAGE_IS_TOO_SHORT;
  }
  if (message.length > 200) {
    throw errorCodes.MESSAGE_IS_TOO_LONG;
  }
};
