import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Filler,
} from "chart.js";
import { useSelector, useDispatch } from "react-redux";
import { Line } from "react-chartjs-2";
import st from "./Elevation.module.css";
import { useTranslation } from "react-i18next";
import Loader from "../Loader/Loader";
import { addElevationIndex } from "../../redux/routeSlice";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip
);

const Elevation = ({ isLoading }) => {
  const [altitudes, setAltitudes] = useState(null);
  const [distances, setDistances] = useState(null);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { selectedItinerary, elevationIndex } = useSelector(
    (state) => state.routeSlice
  );

  useEffect(() => {
    if (selectedItinerary?.properties.distances.length) {
      setAltitudes(selectedItinerary.properties.distances);
      setDistances(selectedItinerary.properties.altitudes);
    } else {
      setAltitudes(null);
      setDistances(null);
    }
  }, [selectedItinerary]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        position: "nearest",
        padding: 5,
        backgroundColor: getComputedStyle(document.body).getPropertyValue(
          "--color-primary"
        ),
        animation: false,
        intersect: false,
        caretSize: 0,
        xAlign: "center",
        yAlign: "center",
        callbacks: {
          label: () => "",
          title: () => "",
        },
        external: (e) => {
          if (e.tooltip.dataPoints[0].dataIndex !== elevationIndex) {
            return dispatch(
              addElevationIndex(e.tooltip.dataPoints[0].dataIndex)
            );
          }
        },
      },
    },
  };

  const data = {
    labels: altitudes,
    datasets: [
      {
        fill: true,
        radius: 0,
        data: distances,
        borderColor: getComputedStyle(document.body).getPropertyValue(
          "--color-secondary"
        ),
        tension: 0.2,
      },
    ],
  };
  const elevationIndexRemove = {
    id: "elevationIndexRemove",
    afterEvent(chart, args) {
      if (args.event.type === "mouseout") {
        dispatch(addElevationIndex(null));
      }
    },
  };

  return (
    <section className={st.container}>
      <h5 className={st.h5}>{t("elevation_title")}</h5>
      <div className={st.content}>
        <div className={st.chart}>
          {distances && (
            <Line
              options={options}
              data={data}
              plugins={[elevationIndexRemove]}
              height={120}
            />
          )}
        </div>
        {isLoading && <Loader />}
      </div>
    </section>
  );
};

export default Elevation;
