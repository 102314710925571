import React from "react";
import st from "./Input.module.css";

const Input = ({ id, type, state, text, setState, error }) => {

  return (
    <div className={st.container}>
      <div className={st.inputForm}>
        <input
          id={id}
          className={st.input}
          type={type}
          placeholder=" "
          value={state}
          onChange={(e) => setState(e.target.value)}
        />
        <span className={st.cut} style={{ width: text.length * 7 + 15}}></span>
        <label htmlFor={id} className={st.placeholder}>
          {text}
        </label>
      </div>
      {error && <span className={st.error}>{error}</span>}
    </div>
  );
};

export default Input;
