import React, { useEffect } from "react";
import { Loader } from "@googlemaps/js-api-loader";

const StreetView = ({ lat, lng }) => {
  useEffect(() => {
    const loader = new Loader({
      apiKey: process.env.REACT_APP_GMAPS_ID,
      version: "weekly",
    });

    const initialize = () => {
      const panorama = new window.google.maps.StreetViewPanorama(
        document.getElementById("street-view"),
        {
          position: { lat, lng },
          zoom: 0,
        }
      );
    };

    loader.load().then(() => {
      initialize();
    });
  }, [lat, lng]);

  return <div id="street-view" style={{ width: "100%", height: "100%" }}></div>;
};

export default StreetView;
