import dayjs from "dayjs";

const dayWeatherAverages = (day, filteredWeather) => {
  const fullDayWeather = filteredWeather.filter(
    (dayMonth) => dayjs(dayMonth.forecastTimeUtc).date() === day
  );

  const weatherParamsArray = (params) =>
    fullDayWeather.map((day) => day[`${params}`]);

  const maxTemp = Math.round(
    Math.round(...weatherParamsArray("airTemperature"))
  );

  const avgWind = Math.round(
    weatherParamsArray("windSpeed").reduce((a, b) => a + b, 0) /
      fullDayWeather.length
  );

  const avgWindDirection = Math.round(
    weatherParamsArray("windDirection").reduce((a, b) => a + b, 0) /
      fullDayWeather.length
  );

  const date = fullDayWeather[0].forecastTimeUtc;

  const avgCondition = () => {
    let dayCond = {
      sun: 0,
      rain: 0,
      thunder: 0,
      sleet: 0,
      hail: 0,
      snow: 0,
      freezingRain: 0,
      fog: 0,
    };

    const WeatherResult = (weatherCondition) => {
      switch (weatherCondition) {
        case "clear":
          return (dayCond.sun += 3);
        case "partly-cloudy":
          return (dayCond.sun += 2);
        case "cloudy-with-sunny-intervals":
          return (dayCond.sun += 1);
        case "cloudy":
          return;
        case "light-rain":
          return (dayCond.rain += 1);
        case "rain":
          return (dayCond.rain += 2);
        case "heavy-rain":
          return (dayCond.rain += 3);
        case "light-snow":
          return (dayCond.snow += 1);
        case "snow":
          return (dayCond.snow += 2);
        case "heavy-snow":
          return (dayCond.snow += 3);
        case "thunder":
          return (dayCond.thunder += 1);
        case "isolated-thunderstorms":
          return (dayCond.thunder += 1), (dayCond.rain += 1);
        case "thunderstorms":
          return (dayCond.thunder += 1), (dayCond.rain += 2);
        case "heavy-rain-with-thunderstorms":
          return (dayCond.thunder += 1), (dayCond.rain += 3);
        case "light-sleet":
          return (dayCond.sleet += 1);
        case "sleet":
          return (dayCond.sleet += 2);
        case "freezing-rain":
          return (dayCond.freezingRain += 1);
        case "hail":
          return (dayCond.hail += 1);
        case "fog":
          return (dayCond.fog += 1);
        default:
          return;
      }
    };

    fullDayWeather.forEach((param) => WeatherResult(param.conditionCode));

    if (dayCond.sun) {
      dayCond.sun = Math.round(0.2 + dayCond.sun / fullDayWeather.length); // increase average by 0.2
    }
    if (dayCond.rain) {
      dayCond.rain = Math.round(0.2 + dayCond.rain / fullDayWeather.length); // increase average by 0.2
    }
    if (dayCond.snow) {
      dayCond.snow = Math.round(0.2 + dayCond.snow / fullDayWeather.length); // increase average by 0.2
    }

    if (dayCond.hail) return "hail";
    if (dayCond.freezingRain) return "freezing-rain";
    if (dayCond.sleet && dayCond.sun) return "sleet-sun";
    if (dayCond.sleet) return "sleet";
    if (dayCond.fog && dayCond.sun) return "fog-sun";
    if (dayCond.fog) return "fog";

    if (dayCond.thunder) {
      if (dayCond.sun) {
        return "thunderstorms-rain-1-sun";
      } else {
        switch (dayCond.rain) {
          case 0:
            return "thunder";
          case 1:
            return "thunderstorms-rain-1";
          case 2:
            return "thunderstorms-rain-2";
          case 3:
            return "thunderstorms-rain-2";
          default:
            break;
        }
      }
    } else if (!dayCond.snow && !dayCond.rain) {
      switch (dayCond.sun) {
        case 3:
          return "clear";
        case 2:
          return "cloudy-1-sun";
        case 1:
          return "cloudy-2-sun";
        case 0:
          return "cloudy";
        default:
          break;
      }
    } else if (dayCond.snow > dayCond.rain) {
      if (dayCond.sun) {
        switch (dayCond.snow) {
          case 1:
            return "snow-1-sun";
          case 2:
            return "snow-2-sun";
          case 3:
            return "snow-3-sun";
          default:
            break;
        }
      } else {
        switch (dayCond.snow) {
          case 1:
            return "snow-1";
          case 2:
            return "snow-2";
          case 3:
            return "snow-3";
          default:
            break;
        }
      }
    } else if (dayCond.sun) {
      switch (dayCond.rain) {
        case 1:
          return "rain-1-sun";
        case 2:
          return "rain-2-sun";
        case 3:
          return "rain-3-sun";
        default:
          break;
      }
    } else {
      switch (dayCond.rain) {
        case 1:
          return "rain-1";
        case 2:
          return "rain-2";
        case 3:
          return "rain-3";
        default:
          break;
      }
    }

    return "cloudy";
  };

  return {
    maxTemp,
    avgWind,
    avgWindDirection,
    avgCondition: avgCondition(),
    date,
  };
};

export default dayWeatherAverages;
