import React, { useState, useEffect } from "react";
import Button from "../components/Button/Button";
import Input from "../components/Input/Input";
import { useTranslation } from "react-i18next";
import st from "./Contact.module.css";
import Loader from "../components/Loader/Loader";
import {
  validateName,
  validateEmail,
  validateMessage,
} from "../helpers/validators";
import API from "../api/index";
import * as errorCodes from "../config/errorCodes";
import Seo from "../components/Seo/Seo";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorName, setErrorName] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorSuccessMessage, setErrorSuccessMessage] = useState("");
  const { t, i18n } = useTranslation();

  const trimEntries = () => {
    setName(name.trim());
    setEmail(email.trim());
    setMessage(message.trim());
  };

  const handleName = () => {
    try {
      validateName(name);
      setErrorName("");
      return true;
    } catch (err) {
      if (err === errorCodes.NAME_IS_REQUIRED)
        setErrorName(t("error_nameRequired"));
      else if (err === errorCodes.NAME_IS_TOO_SHORT)
        setErrorName(t("error_nameTooShort"));
      else if (err === errorCodes.NAME_IS_TOO_LONG)
        setErrorName(t("error_nameTooLong"));
      else setErrorName(t("error_unknown"));
      return false;
    }
  };

  const handleEmail = () => {
    try {
      validateEmail(email);
      setErrorEmail("");
      return true;
    } catch (err) {
      if (err === errorCodes.EMAIL_IS_REQUIRED)
        setErrorEmail(t("error_emailRequired"));
      else if (err === errorCodes.EMAIL_TOO_LONG)
        setErrorEmail(t("error_emailTooLong"));
      else if (err === errorCodes.EMAIL_INCORRECT)
        setErrorEmail(t("error_emailIncorrectFormat"));
      else setErrorEmail(t("error_unknown"));
      return false;
    }
  };

  const handleMessage = () => {
    try {
      validateMessage(message);
      setErrorMessage("");
      return true;
    } catch (err) {
      if (err === errorCodes.MESSAGE_IS_REQUIRED)
        setErrorMessage(t("error_messageRequired"));
      else if (err === errorCodes.MESSAGE_IS_TOO_SHORT)
        setErrorMessage(t("error_messageTooShort"));
      else if (err === errorCodes.MESSAGE_IS_TOO_LONG)
        setErrorMessage(t("error_messageTooLong"));
      else setErrorMessage(t("error_unknown"));
      return false;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const nameValidated = handleName();
    const emailValidated = handleEmail();
    const messageValidated = handleMessage();
    if (!nameValidated || !emailValidated || !messageValidated) {
      setIsLoading(false);
    } else {
      API.sendEmail(
        (emailResponse) => {
          if (emailResponse.status === 200) {
            setName("");
            setEmail("");
            setMessage("");
            setErrorName("");
            setErrorEmail("");
            setErrorMessage("");
            setErrorSuccessMessage(t("contact_successMessage"));
          } else {
            setErrorSuccessMessage(t("contact_failedMessage"));
            console.error(emailResponse);
          }
          setIsLoading(false);
        },
        (error) => {
          setErrorSuccessMessage(t("contact_failedMessage"));
          console.error(error.response?.data);
          setIsLoading(false);
        },
        { name: name, email: email, message: message }
      );
    }
  };

  // NOTE: maybe it is better to transfer (error) translation transition to redux :)
  useEffect(() => {
    if (errorName) handleName();
    if (errorEmail) handleEmail();
    if (errorMessage) handleMessage();
    if (errorSuccessMessage) setErrorSuccessMessage();
  }, [i18n.language]);

  return (
    <>
      <Seo
        title={t("contact_seoTitle")}
        description={t("contact_seoDescription")}
      />
      <main className={st.container}>
        <div className={st.empty}></div>
        <div className={st.contact}>
          <h1 className={st.h1}>{t("contact_title")}</h1>
          <form
            className={st.form}
            onSubmit={handleSubmit}
            onChange={() => errorSuccessMessage && setErrorSuccessMessage("")}
            noValidate
          >
            <Input
              type="text"
              id="contactName"
              text={t("contact_name")}
              state={name}
              setState={setName}
              error={errorName}
            />
            <Input
              type="email"
              id="contactEmail"
              text={t("contact_email")}
              state={email}
              setState={setEmail}
              error={errorEmail}
            />
            <div className={st.containerTextarea}>
              <textarea
                className={st.textarea}
                name="message"
                id="contactMessage"
                placeholder=" "
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
              <span
                className={st.cut}
                style={{ width: t("contact_message").length * 10 }}
              ></span>
              <label htmlFor="contactMessage" className={st.placeholder}>
                {t("contact_message")}
              </label>
              {errorMessage && <span className={st.error}>{errorMessage}</span>}
            </div>
            {isLoading ? (
              <Loader />
            ) : (
              <Button
                text={t("contact_submit")}
                onMouseDown={() => trimEntries()}
                type="submit"
              />
            )}
            {errorSuccessMessage && (
              <div
                className={
                  errorSuccessMessage === t("contact_successMessage")
                    ? st.messageSuccess
                    : st.messageError
                }
              >
                <span>{errorSuccessMessage}</span>
              </div>
            )}
          </form>
        </div>
      </main>
    </>
  );
};

export default Contact;
