import React from "react";
import st from "./CustomIcon.module.css";

export const IconCard = ({ CustomIcon, title, distance }) => {
  return (
    <div className={st.iconCard}>
      <CustomIcon
        className={st.icon}
        color="var(--color-primary)"
        title={title}
      />
      <span>
        {distance}
        <small>km</small>
      </span>
    </div>
  );
};

export const IconActiveCard = ({ CustomIcon, title, isActive }) => {
  return (
    <CustomIcon
      className={st.icon}
      color={isActive ? "var(--color-secondary)" : "var(--color-grey)"}
      title={title}
    />
  );
};
