import st from "./Footer.module.css";
import { FaBicycle } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import dayjs from "dayjs";

const Footer = () => {
    const { t } = useTranslation();

    return (
        <footer className={st.footer}>
            <p className={st.copyright}>&copy;&nbsp;
                <FaBicycle style={{ fontSize: "1.7em", verticalAlign: 'middle', color: "var(--color-secondary)" }} />
                <span style={{ color: "var(--color-white)" }}>Day</span><i style={{ color: "var(--color-secondary)" }}>Ride</i>
                , {dayjs().format("YYYY")} {t('footer_copyrights')}</p>
        </footer>
    )
}

export default Footer
