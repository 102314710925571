import React from "react";
import st from "./Timetables.module.css";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { FaLongArrowAltRight } from "react-icons/fa";
import { TRAIN } from "../../config/constants";
import { FaTrain } from "react-icons/fa";
import { IoBus } from "react-icons/io5";

const Timetables = () => {
  const { t } = useTranslation();
  const { selectedMarker, trainOrigin, busOrigin, timetables } = useSelector(
    (state) => state.transportSlice
  );
  const isTrain = selectedMarker.properties.type === TRAIN;
  const originTitle = isTrain ? trainOrigin?.BusStopName : busOrigin?.name;
  const isTimetable = !!timetables.forward.length || !!timetables.return.length;

  const Timetable = ({ origin, destination, timetable }) => {
    return (
      <div className={st.container}>
        <div className={st.popupContent_PublicTransport_header}>
          <span>{origin}</span>
          <FaLongArrowAltRight size={25} />
          <span>{destination}</span>
        </div>
        {timetable.length ? (
          <ul className={st.popupContent_PublicTransport}>
            <li key="title" className={st.popupContent_PublicTransport_title}>
              <span>{t("timetable_departure")}</span>
              <span>{t("timetable_bikeSlots")}</span>
              <span>{t("timetable_changes")}</span>
              <span>{t("timetable_arrival")}</span>
            </li>
            {timetable.map((trip) => {
              return (
                <li
                  key={trip.id}
                  className={st.popupContent_PublicTransport_row}
                >
                  <span>{trip.departureTime}</span>
                  <span>{trip.availableBikeSlots}</span>
                  <span>{trip.changes}</span>
                  <span>{trip.arrivalTime}</span>
                </li>
              );
            })}
          </ul>
        ) : (
          <span className={st.noPublicTransport}>
            {t("timetable_noTimetables")}
          </span>
        )}
      </div>
    );
  };

  return (
    <div
      className={[
        st.publicTransport,
        isTimetable && st.publicTransportActive,
      ].join(" ")}
    >
      <h5 className={st.h5}>
        {isTimetable &&
          (isTrain ? t("timetable_title_train") : t("timetable_title_bus"))}
      </h5>
      {isTimetable && (
        <>
          <Timetable
            origin={originTitle}
            destination={selectedMarker.properties.title}
            timetable={timetables.forward}
          />
          <Timetable
            origin={selectedMarker.properties.title}
            destination={originTitle}
            timetable={timetables.return}
          />
        </>
      )}
      <div
        className={st.transportSign}
        style={{ borderColor: isTrain ? "var(--color-train)" : "var(--color-bus)" }}
      >
        {isTrain ? <FaTrain size={45} /> : <IoBus size={45} />}
      </div>
    </div>
  );
};

export default Timetables;
