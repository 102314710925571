import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import English from "./english.json";
import Lithuanian from "./lithuanian.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: 'lt',
    supportedLngs: ['lt', 'en'],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: English,
        title: "En",
      },
      lt: {
        translation: Lithuanian,
        title: "Lt"
      },
    },
  });
  
  export default i18n;
