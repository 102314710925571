import { initialState } from "../redux/routeSlice";

export const checkFiltersChange = (currentFilters) => {
  const initialFilters = initialState.filters;
  return !(
    currentFilters.difficultyFilter.every(
      (value, index) => value === initialFilters.difficultyFilter[index]
    ) &&
    currentFilters.isJustRollerbladesFilter ===
      initialFilters.isJustRollerbladesFilter &&
    currentFilters.distanceFilter[0] === initialFilters.distanceFilter[0] &&
    currentFilters.distanceFilter[1] === initialFilters.distanceFilter[1] &&
    currentFilters.isRangeFilter === initialFilters.isRangeFilter &&
    currentFilters.rangeFilter === initialFilters.rangeFilter
  );
};
