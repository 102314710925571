import React, { useState, useEffect } from "react";
import st from "./Slider.module.css";
import { Slider } from "primereact/slider";
import { initialState } from "../../redux/routeSlice";

const minSpace = 10;

export const SingleSlider = ({ value, onSlideEnd, dimension }) => {
  const [distance, setDistance] = useState(value);

  return (
    <div className={st.container}>
      <Slider
        value={distance}
        onChange={(e) => setDistance(e.value)}
        onSlideEnd={(e) => onSlideEnd(e.value)}
        className={st.slider}
        max={initialState.filters.rangeFilter + 100}
      />
      <span className={st.slider_number}>
        {distance}
        {dimension}
      </span>
    </div>
  );
};

export const RangeSlider = ({ rangeValues, onSlideEnd, dimension }) => {
  const [range, setRange] = useState(rangeValues);

  useEffect(() => {
    if (rangeValues[0] !== range[0] || rangeValues[1] !== range[1]) {
      setRange(rangeValues);
    }
  }, [rangeValues]);

  const handleSlide = (e) => {
    const [startLimit, endLimit] = e.value;

    if (startLimit + minSpace <= endLimit) {
      setRange([startLimit, endLimit]);
    }
  };

  return (
    <div className={st.container}>
      <span className={st.slider_number}>
        {range[0]}
        {dimension}
      </span>
      <Slider
        value={range}
        onChange={handleSlide}
        onSlideEnd={(e) => onSlideEnd(e.value)}
        className={st.slider}
        max={initialState.filters.distanceFilter[1]}
        range
      />
      <span className={st.slider_number}>
        {range[1]}
        {dimension}
      </span>
    </div>
  );
};
