import st from "./About.module.css";
import { useTranslation } from "react-i18next";
import Seo from "../components/Seo/Seo";

const About = () => {
  const { t } = useTranslation();

  return (
    <>
      <Seo
        title={t("about_seoTitle")}
        description={t("about_seoDescription")}
      />
      <main className={st.container}>
        <h1 className={st.h1}>{t("about_title")}</h1>
        <article className={st.about}>
          <article className={st.card}>
            <h4 className={st.h4}>{t("about_ideaTitle")}</h4>
            <p>{t("about_idea")}</p>
          </article>

          <article className={st.card}>
            <h4 className={st.h4}>{t("about_demandTitle")}</h4>
            <p>{t("about_demand")}</p>
          </article>

          <article className={st.card}>
            <h4 className={st.h4}>{t("about_crouwdSourcingTitle")}</h4>
            <p>{t("about_crouwdSourcing")}</p>
          </article>

          <article className={st.card}>
            <h4 className={st.h4}>{t("about_futureTitle")}</h4>
            <p>{t("about_future")}</p>
          </article>
        </article>
      </main>
    </>
  );
};

export default About;
