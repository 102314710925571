import React from "react";
import st from "./RoutePopup.module.css";
import { useTranslation } from "react-i18next";
import { TbArrowWaveRightUp, TbRollerSkating, TbShip } from "react-icons/tb";
import { IoBus } from "react-icons/io5";
import { GiCrossroad, GiMountainRoad, GiHorizonRoad } from "react-icons/gi";
import { FaTrain } from "react-icons/fa";
import { FaRoad } from "react-icons/fa6";
import { routeDifficulty } from "../../config/constants";
import { IconCard, IconActiveCard } from "../CustomIcon/CustomIcon";

const RoutePopup = ({ route }) => {
  const { t } = useTranslation();

  return (
    <article className={st.container}>
      <h2 className={st.h2}>{route.title}</h2>
      <div className={st.icons}>
        <div className={st.iconCard}>
          <span className={st.distance}>{route.distance}</span>
          <small>km</small>
        </div>
        <IconCard
          CustomIcon={FaRoad}
          title={t("routes_card_asphalt")}
          distance={route.asphaltRoad}
        />
        <IconCard
          CustomIcon={GiHorizonRoad}
          title={t("routes_card_gravel")}
          distance={route.gravelRoad}
        />
        <IconCard
          CustomIcon={GiMountainRoad}
          title={t("routes_card_offroad")}
          distance={route.offRoad}
        />
        <IconCard
          CustomIcon={GiCrossroad}
          title={t("routes_card_bikepath")}
          distance={route.bikePath}
        />
      </div>
      <div className={st.icons}>
        <TbArrowWaveRightUp
          className={st.icon}
          color={routeDifficulty[route.difficulty]}
          title={t("routes_card_difficulty")}
        />

        <IconActiveCard
          CustomIcon={TbRollerSkating}
          title={t("routes_card_isSmooth")}
          isActive={route.isSmooth}
        />
        <IconActiveCard
          CustomIcon={TbShip}
          title={t("routes_card_ferry")}
          isActive={route.isFerry}
        />
        <IconActiveCard
          CustomIcon={IoBus}
          title={t("routes_card_bus")}
          isActive={route.isBus}
        />
        <IconActiveCard
          CustomIcon={FaTrain}
          title={t("routes_card_train")}
          isActive={route.isTrain}
        />
      </div>
    </article>
  );
};

export default RoutePopup;
