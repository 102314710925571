import React, { useState, useEffect } from "react";
import RouteCard from "../RouteCard/RouteCard";
import st from "./RoutesList.module.css";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../Loader/Loader";
import { useTranslation } from "react-i18next";
import { removeSelectedRoute } from "../../redux/routeSlice";
import { RangeSlider } from "../Slider/Slider";
import {
  updateDistanceFilter,
  updateRollerbladesFilter,
  updateDifficultyFilter,
} from "../../redux/routeSlice";
import LegendButton from "../Legend/Legend";
import { FaFilter } from "react-icons/fa";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { TbArrowWaveRightUp, TbRollerSkating } from "react-icons/tb";
import { Checkbox } from "primereact/checkbox";
import { routeDifficulty } from "../../config/constants";
import Seo from "../Seo/Seo";

const RoutesList = () => {
  const [isDialogVisible, setIsDialogVisible] = useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { routesGeojson, filteredRoutesGeojson, filters } = useSelector(
    (state) => state.routeSlice
  );

  const handleChange = (i) => {
    const newDifficultyFilter = [...filters.difficultyFilter];
    newDifficultyFilter[i] = !newDifficultyFilter[i];

    dispatch(updateDifficultyFilter(newDifficultyFilter));
  };

  const FilterRow = ({ id, value, setValue, text }) => (
    <div className={st.dialog_row} onClick={setValue}>
      <Checkbox inputId={id} onChange={null} checked={value} />
      <label htmlFor={id} className={st.label}>
        {text}
      </label>
    </div>
  );

  useEffect(() => {
    dispatch(removeSelectedRoute());
  }, []);

  return (
    <>
      <Seo
        title={t("routes_seoTitle")}
        description={t("routes_seoDescription")}
      />
      <article className={st.container}>
        <div className={st.header}>
          <h1 className={st.h1}>{t("routes_title")}</h1>
          <Button
            icon={<FaFilter />}
            onClick={() => setIsDialogVisible(true)}
          />
          <div className={st.sliderRoutelist}>
            <RangeSlider
              rangeValues={filters.distanceFilter}
              onSlideEnd={(distance) =>
                dispatch(updateDistanceFilter(distance))
              }
              dimension="km"
            />
          </div>
          <LegendButton />
        </div>
        <ul className={st.RoutesList}>
          {filteredRoutesGeojson && filteredRoutesGeojson.features.length ? (
            filteredRoutesGeojson.features?.map((route) => (
              <RouteCard
                key={route.properties.title}
                route={route.properties}
              />
            ))
          ) : routesGeojson && routesGeojson.features.length ? (
            <li className={st.noRoutes}>{t("routes_noRoutes")}</li>
          ) : (
            <Loader />
          )}
        </ul>
        <Dialog
          header={t("routes_filters_title")}
          visible={isDialogVisible}
          onHide={() => setIsDialogVisible(false)}
          draggable={false}
          className={st.dialog}
        >
          <div className={st.sliderFilter}>
            <RangeSlider
              rangeValues={filters.distanceFilter}
              onSlideEnd={(distance) =>
                dispatch(updateDistanceFilter(distance))
              }
              dimension="km"
            />
          </div>
          <br />

          {filters.difficultyFilter.map((level, i) => (
            <FilterRow
              id={i}
              key={i}
              value={filters.difficultyFilter[i]}
              setValue={() => handleChange(i)}
              text={
                <>
                  <TbArrowWaveRightUp color={routeDifficulty[i]} />
                  {t("routes_filters_difficulties", { returnObjects: true })[i]}
                </>
              }
            />
          ))}

          <br />
          <FilterRow
            id="rollerblades"
            value={filters.isJustRollerbladesFilter}
            setValue={() =>
              dispatch(
                updateRollerbladesFilter(!filters.isJustRollerbladesFilter)
              )
            }
            text={
              <>
                <TbRollerSkating color="var(--color-primary)" />
                {t("routes_filters_rollerblades")}
              </>
            }
          />
        </Dialog>
      </article>
    </>
  );
};

export default RoutesList;
