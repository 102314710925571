// export const PASSWORD_REQUIRED = "PASSWORD_REQUIRED";
// export const PASSWORD_TOO_SHORT = "PASSWORD_TOO_SHORT";
// export const PASSWORD_TOO_LONG = "PASSWORD_TOO_LONG";
// export const PASSWORD_REQUIREMENTS_NOT_FULFILLED = "PASSWORD_REQUIREMENTS_NOT_FULFILLED";
// export const PASSWORD_INCORRECT = "PASSWORD_INCORRECT";
export const EMAIL_IS_REQUIRED = "EMAIL_IS_REQUIRED";
export const EMAIL_TOO_LONG = "EMAIL_TOO_LONG";
export const EMAIL_INCORRECT = "EMAIL_INCORRECT";
// export const EMAIL_TAKEN = "EMAIL_TAKEN";
// export const EMAIL_NOT_FOUND = "EMAIL_NOT_FOUND";
// export const USERNAME_TAKEN = "USERNAME_TAKEN";
// export const USERNAME_REQUIRED = "USERNAME_REQUIRED";
// export const USERNAME_TOO_SHORT = "USERNAME_TOO_SHORT";
// export const USERNAME_TOO_LONG = "USERNAME_TOO_LONG";
// export const USERNAME_INVALID = "USERNAME_INVALID";
// export const USERNAME_INCORRECT = "USERNAME_INCORRECT";
// export const USER_NOT_FOUND = "USER_NOT_FOUND";
export const NAME_IS_REQUIRED = "NAME_IS_REQUIRED";
export const NAME_IS_TOO_SHORT = "NAME_IS_TOO_SHORT"
export const NAME_IS_TOO_LONG = "NAME_IS_TOO_LONG"
// export const NAME_INVALID = "NAME_INVALID";
// export const CITY_IS_REQUIRED = "CITY_IS_REQUIRED";
export const MESSAGE_IS_REQUIRED = "MESSAGE_IS_REQUIRED";
export const MESSAGE_IS_TOO_SHORT = "MESSAGE_IS_TOO_SHORT";
export const MESSAGE_IS_TOO_LONG = "MESSAGE_IS_TOO_LONG";
// export const UNKNOWN_ERROR = "UNKNOWN_ERROR";