import axios from "axios";

const domain = process.env.REACT_APP_SERVER_DOMAIN;

export const getRoutes = async (success, failure) => {
  try {
    const result = await axios.get(`${domain}/routes`);
    success(result.data);
  } catch (error) {
    failure(error);
  }
};

export const getRoute = async (success, failure, id) => {
  try {
    const result = await axios.get(`${domain}/routes/${id}`);
    success(result.data);
  } catch (error) {
    failure(error);
  }
};

export const getWeather = async (success, failure, location) => {
  try {
    const result = await axios.get(`${domain}/weather/${location}`);
    success(result.data);
  } catch (error) {
    failure(error);
  }
};

export const getTrainStops = async (success, failure) => {
  try {
    const result = await axios.get(`${domain}/trains/stations`);
    success(result.data);
  } catch (error) {
    failure(error);
  }
};

export const getTrainTimetables = async (
  success,
  failure,
  origin,
  destination,
  tripDate
) => {
  try {
    const result = await axios.get(
      `${domain}/trains/timetables?origin=${origin}&tripDate=${tripDate}&destination=${destination}`
    );
    success(result.data);
  } catch (error) {
    failure(error);
  }
};

export const getFerriesPorts = async (success, failure) => {
  try {
    const [ferriesResponse, portsResponse] = await Promise.all([
      axios.get(`${domain}/ferries`),
      axios.get(`${domain}/ports`),
    ]);
    const result = {
      ferries: ferriesResponse.data,
      ports: portsResponse.data,
    };
    success(result);
  } catch (error) {
    failure(error);
  }
};

export const getParking = async (success, failure) => {
  try {
    const result = await axios.get(`${domain}/parkings`);
    success(result.data);
  } catch (error) {
    failure(error);
  }
};

export const sendEmail = async (success, failure, formData) => {
  try {
    const result = await axios.post(`${domain}/send-email`, formData);
    success(result);
  } catch (error) {
    failure(error);
  }
};

export const getBusStations = async (success, failure) => {
  try {
    const result = await axios.get(`${domain}/buses/stations`);
    success(result.data);
  } catch (error) {
    failure(error);
  }
};

export const getBusStop = async (success, failure, location) => {
  try {
    const result = await axios.get(`${domain}/buses/stop/${location}`);
    success(result.data);
  } catch (error) {
    failure(error);
  }
};

export const getBusTimetables = async (
  success,
  failure,
  origin,
  destination,
  tripDate
) => {
  try {
    const result = await axios.get(
      `${domain}/buses/timetables?origin=${origin}&tripDate=${tripDate}&destination=${destination}`
    );
    success(result.data);
  } catch (error) {
    failure(error);
  }
};

export const getAltitudes = async (success, failure, coordinates) => {
  try {
    const requestBody = { locations: coordinates };
    const result = await axios.post(
      "https://api.open-elevation.com/api/v1/lookup",
      requestBody
    );
    success(result.data.results);
  } catch (error) {
    failure(error);
  }
};

const API = {
  getRoutes,
  getRoute,
  getWeather,
  getTrainStops,
  getTrainTimetables,
  getFerriesPorts,
  getParking,
  sendEmail,
  getBusStop,
  getBusStations,
  getBusTimetables,
  getAltitudes,
};

export default API;
