import React from "react";
import { FaTrain } from "react-icons/fa";
import { IoBus } from "react-icons/io5";
import { TbShip } from "react-icons/tb";
import { GiRadarSweep } from "react-icons/gi";
import { MdLocalParking, MdEuro } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { Marker } from "react-map-gl";
import { useDispatch } from "react-redux";
import {
  addSelectedMarker,
  addTrainTimetables,
  addBusTimetables,
} from "../../redux/transportSlice";
import { updateRangeFilterLocation } from "../../redux/routeSlice";
import { TRAIN, BUS, PARKING } from "../../config/constants";

const icon = `M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
  c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
  C20.1,15.8,20.2,15.8,20.2,15.7z`;
const buttonOptions = {
  radar: {
    icon: GiRadarSweep,
    color: "var(--color-navigation)",
    tooltip: "map_radar",
  },
  train: {
    icon: FaTrain,
    color: "var(--color-train)",
    tooltip: "map_train",
  },
  bus: {
    icon: IoBus,
    color: "var(--color-bus)",
    tooltip: "map_bus",
  },
  ferry: {
    icon: TbShip,
    color: "var(--color-port)",
    tooltip: "map_port",
  },
  parking: {
    icon: MdLocalParking,
    color: "var(--color-parking)",
    tooltip: "map_parking",
  },
};

const CustomMarkers = ({
  type,
  IconComponent = buttonOptions[type].icon,
  transportStopsGeojson,
  isDraggable,
  onClick,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return transportStopsGeojson.features?.map((marker, index) => (
    <Marker
      key={`${type}-marker-${index}`}
      longitude={marker.geometry.coordinates[0]}
      latitude={marker.geometry.coordinates[1]}
      anchor="bottom"
      style={{ height: 27 }}
      onClick={(e) => {
        (marker.properties.type === TRAIN || marker.properties.type === BUS) &&
          window.innerWidth < 577 &&
          onClick(marker.geometry);
        dispatch(addTrainTimetables({ forward: [], return: [] }));
        dispatch(addBusTimetables({ forward: [], return: [] }));
        dispatch(addSelectedMarker(marker));
        // NOTE: in docs stopPropagation is not needed
        e.originalEvent.stopPropagation();
      }}
      draggable={isDraggable}
      onDragEnd={(e) =>
        dispatch(updateRangeFilterLocation([e.lngLat.lng, e.lngLat.lat]))
      }
    >
      <svg
        height="30"
        viewBox="0 0 24 24"
        style={{
          cursor: "pointer",
          stroke: "none",
          fill: buttonOptions[type].color,
        }}
      >
        <path d={icon} />
        <circle fill="white" cx="50%" cy="10" r="9" />
        <svg viewBox="0 -4 12 24">
          <IconComponent title={t(buttonOptions[type].tooltip)} />
        </svg>
        {marker.properties.type === PARKING && !marker.properties.isFree && (
          <MdEuro size={7} x={12} y={10} />
        )}
      </svg>
    </Marker>
  ));
};

export default CustomMarkers;
