import React from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Seo = ({ title, description }) => {
  const url = useLocation().pathname;
  const { i18n } = useTranslation();

  return (
    <Helmet>
      <title>{title} - dayride.lt</title>
      {description && (
        <meta name="description" content={description} lang={i18n.language} />
      )}
      <meta property="og:title" content={title} />
      {description && <meta property="og:description" content={description} />}
      <meta property="og:url" content={`https://dayride.lt${url}`}></meta>
      <meta name="twitter:title" content={title} />
      {description && <meta name="twitter:description" content={description} />}
      <link rel="canonical" href={`https://dayride.lt${url}`} />
    </Helmet>
  );
};

export default Seo;
