import React, { useState, useEffect } from "react";
import st from "./Map.module.css";
import { useTranslation } from "react-i18next";
import { FaStreetView, FaHome, FaRegCreditCard, FaTrain } from "react-icons/fa";
import { IoBus } from "react-icons/io5";
import { TbShip } from "react-icons/tb";
import { MdLocalParking } from "react-icons/md";
import { TRAIN, BUS, FERRY, PARKING } from "../../config/constants";
import { Popup } from "react-map-gl";
import { useSelector, useDispatch } from "react-redux";
import {
  addSelectedMarker,
  addTrainOrigin,
  addBusOrigin,
  addTrainTimetables,
  addBusTimetables,
} from "../../redux/transportSlice";
import API from "../../api";
import Loader from "../Loader/Loader";
import { Dropdown } from "primereact/dropdown";
import { AutoComplete } from "primereact/autocomplete";
import Calendar from "../Calendar/Calendar";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import StreetView from "../StreetView/StreetView";
import PublicTransportInfoButton from "../PublicTransportInfo/PublicTransportInfo";

const CustomPopup = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDialogVisible, setIsDialogVisible] = useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    trainStops,
    ferries,
    selectedMarker,
    tripDate,
    trainOrigin,
    busOrigin,
    timetables,
  } = useSelector((state) => state.transportSlice);

  const TrainContentInfo = () => {
    if (!trainOrigin) {
      return (
        <span className={st.popupContent_notification}>
          {t("map_popup_enterHomeStation")}
        </span>
      );
    } else if (isLoading) {
      return <Loader />;
    } else if (timetables.forward.length || timetables.return.length) {
      return (
        <Link
          to={`https://bilietas.ltglink.lt/journeys?oStop=${trainOrigin.BusStopId}&dStop=${selectedMarker.properties.id}&oDate=${tripDate}&fareClasses=BONUS_SCHEME_GROUP.ADULT,1`}
          target="_blank"
          rel="noreferrer"
          className={st.buyButton}
        >
          <Button label={t("map_popup_buyTicket")} outlined>
            <FaRegCreditCard
              size={25}
              color="var(--color-secondary)"
              title={t("map_buyTicket_text")}
            />
          </Button>
        </Link>
      );
    } else {
      return (
        <>
          <span className={st.popupContent_notification}>
            {t("map_popup_apologiesNoTransport")}
          </span>
          <div className={st.popupContent_notification_container}>
            <span className={st.popupContent_notification}>
              {t("map_popup_info")}
            </span>
            <PublicTransportInfoButton />
          </div>
        </>
      );
    }
  };

  const TrainContent = () => {
    return (
      <div className={st.popupContent}>
        <Calendar />
        <Dropdown
          value={trainOrigin}
          onChange={(e) => dispatch(addTrainOrigin(e.value))}
          options={trainStops}
          optionLabel="BusStopName"
          placeholder={t("map_popup_selectHome")}
          emptyFilterMessage={t("map_popup_noMatches")}
          filter
          filterBy="BusStopName,Aliases"
          dropdownIcon={<FaHome size={23} />}
        />
        <TrainContentInfo />
      </div>
    );
  };

  const BusContentInfo = () => {
    if (!busOrigin) {
      return (
        <span className={st.popupContent_notification}>
          {t("map_popup_enterHomeStation")}
        </span>
      );
    } else if (isLoading) {
      return <Loader />;
    } else if (timetables.forward.length || timetables.return.length) {
      return (
        <Link
          to={`https://www.autobusubilietai.lt/search?departureTime=00:00&departureDate=${tripDate}&returnTime=null&returnDate=null&isForward=true&onlyDirect=false&onlyInternet=false&onlyBikes=false&from=${busOrigin.number}&fromStop=${busOrigin.name}&to=${selectedMarker.properties.code}&toStop=${selectedMarker.properties.title}`}
          target="_blank"
          rel="noreferrer"
          className={st.buyButton}
        >
          <Button label={t("map_popup_buyTicket")} outlined>
            <FaRegCreditCard
              size={25}
              color="var(--color-secondary)"
              title={t("map_buyTicket_text")}
            />
          </Button>
        </Link>
      );
    } else {
      return (
        <>
          <span className={st.popupContent_notification}>
            {t("map_popup_apologiesNoTransport")}
          </span>
          <div className={st.popupContent_notification_container}>
            <span className={st.popupContent_notification}>
              {t("map_popup_info")}
            </span>
            <PublicTransportInfoButton />
          </div>
        </>
      );
    }
  };

  const BusContent = () => {
    const [inputText, setInputText] = useState(busOrigin);
    const [busStopsList, setBusStopsList] = useState([]);

    const onBusStopChange = (event) => {
      API.getBusStop(
        (data) => {
          setBusStopsList(data);
        },
        (error) => console.error("Error fetching bus stops:", error),
        event.query
      );
    };

    return (
      <div className={st.popupContent}>
        <Calendar />
        <AutoComplete
          value={inputText}
          suggestions={busStopsList}
          placeholder={t("map_popup_selectHome")}
          completeMethod={onBusStopChange}
          onChange={(e) => setInputText(e.target.value)}
          onSelect={(e) => dispatch(addBusOrigin(e.value))}
          dropdownIcon={<FaHome size={23} />}
          dropdownMode="current"
          field="name"
          minLength={2}
          emptyMessage={t("map_popup_noMatches")}
          dropdown
        />
        <BusContentInfo />
      </div>
    );
  };

  const PortContent = () => {
    const filteredFerries = ferries.filter((ferry) =>
      ferry.ports.includes(selectedMarker?.properties.portId)
    );

    return (
      <ul className={st.popupContent}>
        {filteredFerries.map((ferry) => (
          <li key={ferry._id} className={st.popupContent_item}>
            <div className={st.popupContent_item_row}>
              <span>{t("map_ferry")}:</span>
              <Link
                to={ferry.url}
                target="_blank"
                rel="noreferrer"
                className={st.popupLink}
              >
                "{ferry.title}"
              </Link>
            </div>
            <div className={st.popupContent_item_row}>
              <span>{t("map_route")}:</span>
              <span className={st.popupLink}>{ferry.description}</span>
            </div>
          </li>
        ))}
      </ul>
    );
  };

  const ParkingContent = () => {
    return (
      <div className={st.popupContent}>
        <div className={st.popupContent_item_row}>
          <span>{t("map_parkingSpot")}</span>
          <span>
            {selectedMarker.properties.isFree
              ? t("map_freeParking")
              : t("map_paidParking")}
          </span>
        </div>
      </div>
    );
  };

  const PopupHeaderIcon = () => {
    switch (selectedMarker.properties.type) {
      case FERRY:
        return <TbShip size={19} color="var(--color-port)" />;
      case TRAIN:
        return <FaTrain size={17} color="var(--color-train)" />;
      case BUS:
        return <IoBus size={19} color="var(--color-bus)" />;
      case PARKING:
        return <MdLocalParking size={15} color="var(--color-parking)" />;
      default:
        break;
    }
  };

  const PopupContent = () => {
    switch (selectedMarker.properties.type) {
      case FERRY:
        return <PortContent />;
      case TRAIN:
        return <TrainContent />;
      case BUS:
        return <BusContent />;
      case PARKING:
        return <ParkingContent />;
      default:
        break;
    }
  };

  useEffect(() => {
    if (selectedMarker.properties.type === BUS && busOrigin?.id) {
      setIsLoading(true);
      API.getBusTimetables(
        (fetchedBusTimetables) => {
          dispatch(addBusTimetables(fetchedBusTimetables));
          setIsLoading(false);
        },
        (error) => console.error("CustomPopup error:", error),
        busOrigin.number,
        selectedMarker.properties.code,
        tripDate
      );
    }

    if (selectedMarker.properties.type === TRAIN && trainOrigin?.BusStopId) {
      setIsLoading(true);
      API.getTrainTimetables(
        (fetchedTrainTimetables) => {
          dispatch(addTrainTimetables(fetchedTrainTimetables));
          setIsLoading(false);
        },
        (error) => console.error("CustomPopup error:", error),
        trainOrigin.BusStopId,
        selectedMarker.properties.id,
        tripDate
      );
    }
  }, [selectedMarker, tripDate, trainOrigin, busOrigin]);

  return (
    <Popup
      className={st.popup}
      closeButton={false}
      latitude={selectedMarker.geometry.coordinates[1]}
      longitude={selectedMarker.geometry.coordinates[0]}
      onClose={() => {
        dispatch(addTrainTimetables({ forward: [], return: [] }));
        dispatch(addBusTimetables({ forward: [], return: [] }));
        dispatch(addSelectedMarker(null));
      }}
    >
      <div className={st.popupHeader}>
        <div className={st.popupHeaderIcon}>
          <PopupHeaderIcon />
        </div>
        <h6 className={st.h6}>{selectedMarker.properties.title}</h6>
        <Link
          to={`${t("map_streetview_url")}${
            selectedMarker.geometry.coordinates[1]
          },${selectedMarker.geometry.coordinates[0]}`}
          target="_blank"
          rel="noreferrer"
          className={st.popupUrl}
        >
          <FaStreetView
            color="var(--color-secondary)"
            title={t("map_streetview_text")}
          />
        </Link>
        <button
          className={st.popupButton}
          onClick={() => setIsDialogVisible(true)}
        >
          <FaStreetView
            color="var(--color-secondary)"
            title={t("map_streetview_text")}
          />
        </button>
      </div>
      <PopupContent />
      <Dialog
        header={selectedMarker.properties.title}
        visible={isDialogVisible}
        onHide={() => setIsDialogVisible(false)}
        draggable={false}
        className={st.dialog}
      >
        <StreetView
          lat={selectedMarker.geometry.coordinates[1]}
          lng={selectedMarker.geometry.coordinates[0]}
        />
      </Dialog>
    </Popup>
  );
};

export default CustomPopup;
