import React from "react";
import st from "./Contribute.module.css";
import { useTranslation } from "react-i18next";
import LegendButton from "../components/Legend/Legend";
import Seo from "../components/Seo/Seo";

const Contribute = () => {
  const { t } = useTranslation();

  return (
    <>
      <Seo
        title={t("contribute_seoTitle")}
        description={t("contribute_seoDescription")}
      />
      <main className={st.container}>
        <h1 className={st.h1}>{t("contribute_title")}</h1>
        <article className={st.contribute}>
          <h3>{t("contribute_explanation")}</h3>
          <ul className={st.content}>
            <li>
              <strong>{t("contribute_rules.direction.title")}</strong>
              {t("contribute_rules.direction.content")}
            </li>
            <li>
              <strong>{t("contribute_rules.outOfBounds.title")}</strong>
              {t("contribute_rules.outOfBounds.content")}
            </li>
            <li>
              <strong>{t("contribute_rules.regions.title")}</strong>
              {t("contribute_rules.regions.content")}
            </li>
            <li>
              <strong>{t("contribute_rules.routePlanning.title")}</strong>
              {t("contribute_rules.routePlanning.content")}
            </li>
            <li>
              <strong>{t("contribute_rules.publicTransport.title")}</strong>
              {t("contribute_rules.publicTransport.content")}
            </li>
            <li>
              <strong>{t("contribute_rules.rollerskaters.title")}</strong>
              {t("contribute_rules.rollerskaters.content")}
            </li>
            <li>
              <strong>{t("contribute_rules.placesToVisit.title")}</strong>
              {t("contribute_rules.placesToVisit.content")}
            </li>
            <li>
              <strong>{t("contribute_rules.mtb.title")}</strong>
              {t("contribute_rules.mtb.content")}
            </li>
            <li>
              <strong>{t("contribute_rules.corrections.title")}</strong>
              {t("contribute_rules.corrections.content")}
            </li>
            <li>
              <strong>{t("contribute_rules.additionals.title")}</strong>
              {t("contribute_rules.additionals.content")}
            </li>
            <li>
              <strong>{t("contribute_rules.tools.title")}</strong>
              {t("contribute_rules.tools.content")}
              <br />
              <a
                href="https://map.project-osrm.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <b>https://map.project-osrm.org/</b>
              </a>
            </li>
            <li>
              <strong>{t("contribute_rules.lastWord.title")}</strong>
              {t("contribute_rules.lastWord.content")}
            </li>
          </ul>
          <span className={st.legend}>
            <h3>{t("contribute_legend")}</h3>
            <LegendButton />
          </span>
        </article>
      </main>
    </>
  );
};

export default Contribute;
