// NOTE: can't use var styles (--color-primary, --color-secondary) in map styles
const colors = {
  primary: "#14453b",
  secondary: "#0ed145",
  green: "green",
  orange: "orange",
  red: "red",
  blue: "blue",
  navigation: "#1da1f2",
};

export const routesStyle = {
  id: "routes",
  type: "circle",
  paint: {
    "circle-color": colors.primary,
    "circle-radius": 5,
    "circle-stroke-width": 2,
    "circle-stroke-color": colors.secondary,
  },
};

export const routesExtendedClickAreaStyle = {
  id: "routes-extended",
  type: "circle",
  paint: {
    "circle-radius": 15,
    'circle-opacity': 0,
  },
};

export const routeStyle = {
  id: "route",
  type: "line",
  layout: {
    "line-join": "round",
    "line-cap": "round",
  },
  paint: {
    "line-color": colors.secondary,
    "line-width": 5,
  },
};

export const trainStopsStyle = {
  id: "trainStops",
  type: "circle",
  paint: {
    "circle-color": colors.primary,
    "circle-radius": 2,
    "circle-stroke-width": 1,
    "circle-stroke-color": colors.red,
  },
};

export const busStopsStyle = {
  id: "busStops",
  type: "circle",
  paint: {
    "circle-color": colors.orange,
    "circle-radius": 2,
    "circle-stroke-width": 2,
    "circle-stroke-color": colors.green,
  },
};

export const portsStyle = {
  id: "ports",
  type: "circle",
  paint: {
    "circle-color": colors.primary,
    "circle-radius": 2,
    "circle-stroke-width": 1,
    "circle-stroke-color": colors.blue,
  },
};

export const ferriesStyle = {
  id: "ferries",
  type: "line",
  layout: {
    "line-join": "round",
    "line-cap": "round",
  },
  paint: {
    "line-color": colors.blue,
    "line-width": 5,
    "line-dasharray": [2, 3],
  },
};

export const radarStyle = {
  id: "radar",
  type: "line",
  layout: {
    "line-join": "round",
    "line-cap": "round",
  },
  paint: {
    "line-color": colors.navigation,
    "line-width": 2,
    "line-dasharray": [2, 3],
  },
};

export const elevationIndicatorStyle = {
  id: "elevation",
  type: "circle",
  paint: {
    "circle-color": colors.secondary,
    "circle-radius": 3,
    "circle-stroke-width": 2,
    "circle-stroke-color": colors.primary,
  },
};