const accentLettersAPI = ["ą", "č", "ę", "ė", "į", "š", "ų", "ū", "ž", " "];
const latinLettersAPI = ["a", "c", "e", "e", "i", "s", "u", "u", "z", "-"];
const accentLettersLink = ["Ą", "ą", "Č", "č", "Ę", "ę", "Ė", "ė", "Į", "į", "Š", "š", "Ų", "ų", "Ū", "ū", "Ž", "ž", " "];
const latinLettersLink = ["A", "a", "C", "c", "E", "e", "E", "e", "I", "i", "S", "s", "U", "u", "U", "u", "Z", "z", "-"];

export const formatCityNameAPI = (importedCity) => {
  let city = importedCity.toLowerCase();
  for (let i in accentLettersAPI) {
    if (city.includes(accentLettersAPI[i])) {
      city = city.replaceAll(accentLettersAPI[i], latinLettersAPI[i]);
    }
  }

  return city;
};

export const formatCityNameLink = (importedCity) => {
  let city = importedCity.toLowerCase();
  for (let i in accentLettersLink) {
    if (city.includes(accentLettersLink[i])) {
      city = city.replaceAll(accentLettersLink[i], latinLettersLink[i]);
    }
  }

  return city;
};
