import React from "react";
import st from "./LanguageDropDown.module.css";
import { useTranslation } from "react-i18next";
import { Dropdown } from "primereact/dropdown";

const LanguageDropDown = () => {
  const { i18n } = useTranslation();

  const languageOptions = Object.entries(i18n.store.data).map((lan) => {
    return { value: lan[0], label: lan[1].title };
  });

  return (
    <div className={st.container}>
      <Dropdown
        value={i18n.language}
        onChange={(e) => i18n.changeLanguage(e.target.value)}
        options={languageOptions}
        className={st.dropdown}
      />
    </div>
  );
};

export default LanguageDropDown;
