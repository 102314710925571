import st from "./Button.module.css";

const Button = props => {

    return (
            <button
            className={st.button}
            style={{...props.style}}
            onClick={props.buttonClick}
            onMouseDown={props.onMouseDown}
            type={props.type}
            >{props.text}</button>
    )
}

export default Button
