const convertToFilename = (title) => {
  const truncatedTitle = title.slice(0, 20);
  const sanitizedTitle = truncatedTitle
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/\s+/g, "_")
    .replace(/[^a-z0-9_]/g, "");
  return sanitizedTitle;
};

const cleanUpXML = (xmlString) => {
  // Cleanup function to remove unnecessary white spaces
  const lines = xmlString.split("\n");
  const cleanedLines = lines.map((line) => line.trim());
  return cleanedLines.join("\n");
};

const convertToGPX = (coordinates, elevation, routeTitle, routeId) => {
  const gpxHeader = `<?xml version="1.0" encoding="UTF-8"?>
        <gpx xmlns="http://www.topografix.com/GPX/1/1" version="1.1"
          xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
          xmlns:gpxtpx="http://www.garmin.com/xmlschemas/TrackPointExtension/v1"
          xsi:schemaLocation="http://www.topografix.com/GPX/1/1
          http://www.topografix.com/GPX/1/1/gpx.xsd">
          <metadata>
          <author>
            <name>DayRide</name>
          </author>
          <link href="${process.env.REACT_APP_DOMAIN}/routes/${routeId}">
            <text>${routeTitle}</text>
          </link>
          <name>${routeTitle}</name>
        </metadata>`;

  const gpxFooter = "</gpx>";

  const trackData = coordinates
    .map((coord, index) => {
      return `<trkpt lat="${coord[1]}" lon="${coord[0]}">
          <ele>${elevation[index]}</ele>
          </trkpt>`;
    })
    .join("");

  return `${gpxHeader}<trk><trkseg>${trackData}</trkseg></trk>${gpxFooter}`;
};

const downloadFile = (data, filename) => {
  const blob = new Blob([data], { type: "application/gpx+xml" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};

const createGpx = (coordinates, altitudes, routeTitle, routeId) => {
  const gpxData = convertToGPX(coordinates, altitudes, routeTitle, routeId);
  const cleanedXML = cleanUpXML(gpxData);
  const convertedTitleToFilename = convertToFilename(routeTitle);

  downloadFile(cleanedXML, `${convertedTitleToFilename}.gpx`);
};

export default createGpx;
