import React, {
  useRef,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from "react";
import st from "./Map.module.css";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, generatePath, useLocation } from "react-router-dom";
import Map, {
  Source,
  Layer,
  GeolocateControl,
  NavigationControl,
  ScaleControl,
  FullscreenControl,
  Popup,
} from "react-map-gl/maplibre";
import "maplibre-gl/dist/maplibre-gl.css";
import { useTranslation } from "react-i18next";
import bbox from "@turf/bbox";
import { point, feature, featureCollection } from "@turf/helpers";
import circle from "@turf/circle";
import buffer from "@turf/buffer";
import pointsWithinPolygon from "@turf/points-within-polygon";
import CustomMapButton from "./CustomMapButton";
import CustomMarkers from "./CustomMarker";
import CustomPopup from "./CustomPopup";
import {
  routesStyle,
  routesExtendedClickAreaStyle,
  routeStyle,
  trainStopsStyle,
  busStopsStyle,
  portsStyle,
  ferriesStyle,
  radarStyle,
  elevationIndicatorStyle,
} from "./mapOptions";
import {
  setRangeFilter,
  updateRangeFilter,
  updateRangeFilterLocation,
  resetFilter,
} from "../../redux/routeSlice";
import { TRAIN, BUS, FERRY, PARKING } from "../../config/constants";
import { useLocalStorage } from "primereact/hooks";
import { SingleSlider } from "../Slider/Slider";
import RoutePopup from "../RoutePopup/RoutePopup";
import { checkFiltersChange } from "../../helpers/checkFilters";

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;
const MAPTILER_STYLE = process.env.REACT_APP_MAPTILER_STYLE;
const MAP_BASE_STYLE = process.env.REACT_APP_MAP_BASE_STYLE;
const mapStyle = "mapbox://styles/mapbox/outdoors-v11";
const CLOSE_ZOOM = 8;

const MapComponent = () => {
  const [lng, setLng] = useState(23.9036);
  const [lat, setLat] = useState(55.1985);
  const [zoom, setZoom] = useState(5.5);
  const [hoveredRoute, setHoveredRoute] = useState(null);

  const [isTrainToggle, setIsTrainToggle] = useLocalStorage(
    true,
    "mapButtonTrainState"
  );
  const [isBusToggle, setIsBusToggle] = useLocalStorage(
    true,
    "mapButtonBusState"
  );
  const [isPortToggle, setIsPortToggle] = useLocalStorage(
    true,
    "mapButtonPortState"
  );
  const [isParkingToggle, setIsParkingToggle] = useLocalStorage(
    true,
    "mapButtonParkingState"
  );

  const mapRef = useRef();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isRoutesList = useLocation().pathname === "/routes";

  const {
    selectedRouteInfo,
    filteredRoutesGeojson,
    selectedItinerary,
    elevationIndex,
    filters,
  } = useSelector((state) => state.routeSlice);

  const { trainStops, ports, busStopsGeojson, parkingGeojson, selectedMarker } =
    useSelector((state) => state.transportSlice);

  const onMouseEnter = useCallback(
    (e) => setHoveredRoute(e.features[0].properties),
    []
  );
  const onMouseLeave = useCallback(() => setHoveredRoute(null, []));
  const onClick = useCallback((e) => {
    console.log([e.lngLat.lng, e.lngLat.lat]);
    const feature = e.features && e.features[0];
    if (feature) {
      const path = generatePath("/routes/:id", { id: feature.properties._id });
      navigate(path);
    }
  }, []);

  const bufferedRoute = () =>
    buffer(selectedItinerary.geometry, 5, { units: "kilometers" });

  const elevationIndicatorGeojson = () =>
    point([
      selectedItinerary?.geometry.coordinates[elevationIndex][0],
      selectedItinerary?.geometry.coordinates[elevationIndex][1],
    ]);

  const trainStopsGeojson = featureCollection(
    trainStops.map((trainStop) =>
      point([trainStop.Coordinates.Longitude, trainStop.Coordinates.Latitude], {
        title: trainStop.BusStopName,
        id: trainStop.BusStopId,
        trainStopCode: trainStop.BusStopCode,
        type: TRAIN,
      })
    )
  );

  const portsGeojson = featureCollection(
    ports?.map((port) =>
      feature(port.geometry, {
        portId: port._id,
        title: port.title,
        type: FERRY,
      })
    )
  );

  // NOTE: ferries lines, if needed, remake logic
  // const ferriesGeojson = () => {
  //   let coordinates = [];
  //   const portsList = ferry(selectedMarker?.properties.portId);
  //   portsList?.map((port) => {
  //     console.log(port);
  //     return coordinates.push(
  //       ports.find((port) => port._id === port.ports).geometry.coordinates
  //     );
  //   });
  //   return lineString(coordinates);
  // };

  const trainMarkers = useMemo(() => {
    return (
      <CustomMarkers
        transportStopsGeojson={trainStopsGeojson}
        type="train"
        onClick={(e) =>
          mapRef.current?.flyTo({
            center: e.coordinates,
            duration: 2000,
            offset: { x: 0, y: -110 },
          })
        }
      />
    );
  }, [trainStopsGeojson]);

  const busMarkers = useMemo(() => {
    return (
      <CustomMarkers
        transportStopsGeojson={busStopsGeojson}
        type="bus"
        onClick={(e) =>
          mapRef.current?.flyTo({
            center: e.coordinates,
            duration: 2000,
            offset: { x: 0, y: -110 },
          })
        }
      />
    );
  }, [busStopsGeojson]);

  const parkingMarkers = useMemo(() => {
    if (parkingGeojson && selectedItinerary) {
      const routeParking = pointsWithinPolygon(parkingGeojson, bufferedRoute());
      return (
        <CustomMarkers transportStopsGeojson={routeParking} type="parking" />
      );
    }
    return null;
  }, [parkingGeojson, selectedItinerary]);

  const portMarkers = useMemo(() => {
    return <CustomMarkers transportStopsGeojson={portsGeojson} type="ferry" />;
  }, [portsGeojson]);

  const myLocationMarkers = useMemo(() => {
    return (
      <CustomMarkers
        transportStopsGeojson={featureCollection([
          point(filters.homeLocation, { title: t("map_myLocation") }),
        ])}
        type="radar"
        isDraggable={true}
      />
    );
  }, [portsGeojson]);

  useEffect(() => {
    if (selectedItinerary) {
      mapRef.current?.fitBounds(bbox(selectedItinerary.geometry), {
        padding: 80,
        duration: 1000,
      });
    } else if (selectedRouteInfo) {
      mapRef.current?.flyTo({
        center: selectedRouteInfo.geometry.coordinates,
        zoom: 11,
        duration: 3000,
      });
    } else if (filteredRoutesGeojson?.features?.length === 1) {
      mapRef.current?.flyTo({
        center: filteredRoutesGeojson?.features[0].geometry.coordinates,
        zoom: 10,
        duration: 3000,
      });
    } else if (filteredRoutesGeojson?.features?.length === 0) {
      mapRef.current?.flyTo({
        center: [lng, lat],
        zoom: 6,
        duration: 3000,
      });
    } else {
      mapRef.current?.fitBounds(bbox(filteredRoutesGeojson), {
        padding: 80,
        duration: 1000,
      });
    }
  }, [selectedRouteInfo, selectedItinerary, filteredRoutesGeojson]);

  useEffect(() => {
    if (filters.isRangeFilter && "geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          dispatch(updateRangeFilterLocation([longitude, latitude]));
        },
        (error) => {
          console.error("Error getting geolocation:", error);
        }
      );
    }
  }, [filters.isRangeFilter]);

  return (
    <section className={st.container}>
      <Map
        ref={mapRef}
        initialViewState={{
          latitude: lat,
          longitude: lng,
          zoom: zoom,
        }}
        interactiveLayerIds={["routes", "routes-extended"]}
        className={st.map}
        // mapboxAccessToken={MAPBOX_TOKEN}
        // mapStyle={mapStyle}
        // mapStyle={MAP_BASE_STYLE}
        mapStyle={MAPTILER_STYLE}
        cursor={hoveredRoute ? "pointer" : "auto"}
        onMove={() => setZoom(Math.round(mapRef.current?.getZoom()))}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {isTrainToggle && zoom <= CLOSE_ZOOM && (
          <Source id="trainStops" type="geojson" data={trainStopsGeojson}>
            <Layer {...trainStopsStyle} />
          </Source>
        )}
        {isPortToggle && zoom <= CLOSE_ZOOM && (
          <Source id="ports" type="geojson" data={portsGeojson}>
            <Layer {...portsStyle} />
          </Source>
        )}
        {isBusToggle && zoom <= CLOSE_ZOOM && (
          <Source id="busStops" type="geojson" data={busStopsGeojson}>
            <Layer {...busStopsStyle} />
          </Source>
        )}
        <Source id="routes" type="geojson" data={filteredRoutesGeojson}>
          <Layer {...routesExtendedClickAreaStyle} />
          <Layer {...routesStyle} />
        </Source>
        <Source
          id="route"
          type="geojson"
          data={feature(selectedItinerary?.geometry)}
        >
          <Layer {...routeStyle} />
        </Source>
        {filters.isRangeFilter && isRoutesList && (
          <Source
            id="radar"
            type="geojson"
            data={circle(filters.homeLocation, filters.rangeFilter)}
          >
            <Layer {...radarStyle} />
          </Source>
        )}
        {elevationIndex && selectedItinerary && (
          <Source
            id="elevation"
            type="geojson"
            data={elevationIndicatorGeojson()}
          >
            <Layer {...elevationIndicatorStyle} />
          </Source>
        )}

        {/* NOTE: ferries lines, if needed, remake logic */}
        {/* {isPortToggle && selectedMarker?.properties.portId && (
          <Source id="ferries" type="geojson" data={ferriesGeojson()}>
            <Layer {...ferriesStyle} />
          </Source>
        )} */}

        {isBusToggle && zoom > CLOSE_ZOOM && busMarkers}
        {isTrainToggle && zoom > CLOSE_ZOOM && trainMarkers}
        {isPortToggle && zoom > CLOSE_ZOOM && portMarkers}
        {isParkingToggle && zoom > CLOSE_ZOOM && parkingMarkers}
        {filters.isRangeFilter && isRoutesList && myLocationMarkers}

        {selectedMarker && <CustomPopup />}
        <NavigationControl />
        <FullscreenControl />
        <GeolocateControl trackUserLocation={true} showUserHeading={true} />
        <ScaleControl />
        {hoveredRoute && window.innerWidth >= 961 && (
          <Popup
            longitude={
              JSON.parse(hoveredRoute.locationCoordinates).coordinates[0]
            }
            latitude={
              JSON.parse(hoveredRoute.locationCoordinates).coordinates[1]
            }
            closeButton={false}
            onClose={() => setHoveredRoute(null)}
          >
            <RoutePopup route={hoveredRoute} />
          </Popup>
        )}

        <div className={st.mapBtnContainerLeftTop}>
          {isRoutesList && (
            <CustomMapButton
              type="radar"
              isButtonToggle={filters.isRangeFilter}
              setIsButtonToggle={() => dispatch(setRangeFilter())}
            />
          )}
          <CustomMapButton
            type="train"
            isButtonToggle={isTrainToggle}
            setIsButtonToggle={setIsTrainToggle}
          />
          <CustomMapButton
            type="ferry"
            isButtonToggle={isPortToggle}
            setIsButtonToggle={setIsPortToggle}
          />
          <CustomMapButton
            type="bus"
            isButtonToggle={isBusToggle}
            setIsButtonToggle={setIsBusToggle}
          />
          <CustomMapButton
            type="parking"
            isButtonToggle={isParkingToggle}
            setIsButtonToggle={setIsParkingToggle}
          />
        </div>
        {isRoutesList && (
          <div className={st.mapBtnContainerRightBottom}>
            <CustomMapButton
              type="reset"
              isButtonToggle={checkFiltersChange(filters)}
              setIsButtonToggle={() => dispatch(resetFilter())}
            />
          </div>
        )}
        {filters.isRangeFilter && isRoutesList && (
          <div className={st.slider}>
            <SingleSlider
              value={filters.rangeFilter}
              onSlideEnd={(value) => dispatch(updateRangeFilter(value))}
              dimension="km"
            />
          </div>
        )}
      </Map>
    </section>
  );
};

export default MapComponent;
