import st from "./Home.module.css";
import { useNavigate } from "react-router-dom";
import {
  FaMapMarkedAlt,
  FaRoute,
  FaSubway,
  FaComments,
  FaBiking,
  FaFileDownload,
} from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";

const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const styleIcon = { color: "var(--color-secondary)", fontSize: 64 };

  const Summary = ({ title, text, icon }) => (
    <div className={st.summaryItem}>
      {icon}
      <h4 className={st.h4}>{title}</h4>
      <p className={st.summaryItemText}>{text}</p>
    </div>
  );

  return (
    <main className={st.container}>
      <section className={st.hero}></section>
      <h1 className={st.h1}>{t("home_title")}</h1>
      <div className={st.home}>
        <Button
          raised
          label={t("header_routes")}
          onClick={() => navigate("/routes")}
          style={{ marginRight: 20 }}
        />
        <h2 className={st.h2}>{t("home_heroText")}</h2>
      </div>

      <section className={st.summary}>
        <h3 className={st.h3}>{t("home_summaryTitle")}</h3>
        <div className={st.summaryItems}>
          <Summary
            title={t("home_summary_routesTitle")}
            text={t("home_summary_routes")}
            icon={<FaMapMarkedAlt style={styleIcon} />}
          />
          <Summary
            title={t("home_summary_informationTitle")}
            text={t("home_summary_information")}
            icon={<FaRoute style={styleIcon} />}
          />
          <Summary
            title={t("home_summary_publicTransportTitle")}
            text={t("home_summary_publicTransport")}
            icon={<FaSubway style={styleIcon} />}
          />
          <Summary
            title={t("home_summary_userCommentsTitle")}
            text={t("home_summary_userComments")}
            icon={<FaComments style={styleIcon} />}
          />
          <Summary
            title={t("home_summary_downloadTitle")}
            text={t("home_summary_download")}
            icon={<FaFileDownload style={styleIcon} />}
          />
          <Summary
            title={t("home_summary_itinerariesTitle")}
            text={t("home_summary_itineraries")}
            icon={<FaBiking style={styleIcon} />}
          />
        </div>
      </section>
      <section className={st.bottom}>
        <div className={st.bottomPicture}></div>
      </section>
    </main>
  );
};

export default Home;
