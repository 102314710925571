import React from "react";
import st from "./Loader.module.css";

const Loader = ({absolute}) => {
  return (
      <div className={[st.loader, absolute ? st.absolute : st.static].join(" ")}>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
  );
};

export default Loader;
