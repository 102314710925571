import React, { useEffect } from "react";
import { Calendar } from "primereact/calendar";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import { FaCalendarWeek } from "react-icons/fa";
import { addTripDate } from "../../redux/transportSlice";
import { useTranslation } from "react-i18next";

const CalendarComponent = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const { tripDate } = useSelector((state) => state.transportSlice);

  useEffect(() => {
    const isDateOld = dayjs().isAfter(dayjs(tripDate), "day");
    if (isDateOld) {
      dispatch(addTripDate(dayjs().format("YYYY-MM-DD")));
    }
  }, []);

  return (
    <Calendar
      value={dayjs(tripDate).toDate()}
      onChange={(e) =>
        dispatch(addTripDate(dayjs(e.value).format("YYYY-MM-DD")))
      }
      icon={<FaCalendarWeek size={20} />}
      showIcon
      touchUI={window.innerWidth < 1025 ? true : false}
      minDate={new Date()}
      dateFormat="yy-mm-dd"
      locale={i18n.language}
    />
  );
};

export default CalendarComponent;
