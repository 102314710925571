import React, { useEffect } from "react";
import st from "./Route.module.css";
import { useOutletContext, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Loader/Loader";
import createGpx from "../../helpers/createGpx";
import { useTranslation } from "react-i18next";
import { selectRoute, removeSelectedRoute } from "../../redux/routeSlice";
import { TiWeatherPartlySunny } from "react-icons/ti";
import { TbArrowWaveRightUp, TbRollerSkating, TbShip } from "react-icons/tb";
import { IoBus } from "react-icons/io5";
import { GiCrossroad, GiMountainRoad, GiHorizonRoad } from "react-icons/gi";
import { FaTrain, FaFileDownload } from "react-icons/fa";
import { FaRoad } from "react-icons/fa6";
import { Button } from "primereact/button";
// import { TabView, TabPanel } from "primereact/tabview";
import { routeDifficulty } from "../../config/constants";
import { IconCard, IconActiveCard } from "../CustomIcon/CustomIcon";
import Seo from "../Seo/Seo";

const urlRegex = /(https?:\/\/[^\s]+)/g;

const Route = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { routesGeojson, selectedRouteInfo, selectedItinerary } = useSelector(
    (state) => state.routeSlice
  );
  const { t } = useTranslation();
  const [isRouteSecondaryInfoOpen, setIsRouteSecondaryInfoOpen] =
    useOutletContext();

  const renderTextWithLinks = (text) => {
    if (!text) return null;
    return text.split(urlRegex).map((part, index) => {
      if (part.match(urlRegex)) {
        return (
          <a key={index} href={part} target="_blank" rel="noopener noreferrer">
            <b>{t("routes_url")}</b>
          </a>
        );
      } else {
        return part;
      }
    });
  };

  const handleDownload = () => {
    createGpx(
      selectedItinerary.geometry.coordinates,
      selectedItinerary.properties.altitudes,
      selectedRouteInfo.properties.title,
      selectedRouteInfo.properties._id
    );
  };

  useEffect(() => {
    if (!selectedRouteInfo || selectedRouteInfo.properties._id !== id) {
      dispatch(removeSelectedRoute());
      dispatch(selectRoute(id));
    }
  }, [routesGeojson, id]);

  return (
    <>
      <Seo
        title={`${t("route_seoTitle")}${selectedRouteInfo?.properties.title}`}
      />
      <article className={st.container}>
        {!selectedItinerary && (
          <div className={st.loader}>
            <Loader />
          </div>
        )}
        {selectedRouteInfo ? (
          <div className={st.routesInfo}>
            <div className={st.header}>
              <h2 className={st.h2}>{selectedRouteInfo?.properties.title}</h2>
              <Button
                raised
                className={st.weatherElevationBtn}
                onClick={() =>
                  setIsRouteSecondaryInfoOpen(!isRouteSecondaryInfoOpen)
                }
              >
                <TiWeatherPartlySunny size={18} />
                <span>/</span>
                <TbArrowWaveRightUp size={23} />
              </Button>
            </div>
            <div className={st.icons}>
              <div className={st.iconCard}>
                <span className={st.distance}>
                  {selectedRouteInfo.properties.distance}
                </span>
                <small>km</small>
              </div>
              <IconCard
                CustomIcon={FaRoad}
                title={t("routes_card_asphalt")}
                distance={selectedRouteInfo.properties.asphaltRoad}
              />
              <IconCard
                CustomIcon={GiHorizonRoad}
                title={t("routes_card_gravel")}
                distance={selectedRouteInfo.properties.gravelRoad}
              />
              <IconCard
                CustomIcon={GiMountainRoad}
                title={t("routes_card_offroad")}
                distance={selectedRouteInfo.properties.offRoad}
              />
              <IconCard
                CustomIcon={GiCrossroad}
                title={t("routes_card_bikepath")}
                distance={selectedRouteInfo.properties.bikePath}
              />
            </div>
            <div className={st.icons}>
              <TbArrowWaveRightUp
                className={st.icon}
                color={routeDifficulty[selectedRouteInfo.properties.difficulty]}
                title={t("routes_card_difficulty")}
              />

              <IconActiveCard
                CustomIcon={TbRollerSkating}
                title={t("routes_card_isSmooth")}
                isActive={selectedRouteInfo.properties.isSmooth}
              />
              <IconActiveCard
                CustomIcon={TbShip}
                title={t("routes_card_ferry")}
                isActive={selectedRouteInfo.properties.isFerry}
              />
              <IconActiveCard
                CustomIcon={IoBus}
                title={t("routes_card_bus")}
                isActive={selectedRouteInfo.properties.isBus}
              />
              <IconActiveCard
                CustomIcon={FaTrain}
                title={t("routes_card_train")}
                isActive={selectedRouteInfo.properties.isTrain}
              />
            </div>

            {/* NOTE: In future, when images and comments added, fix mobile view */}
            {/* <TabView>
                <TabPanel header="Description" leftIcon={<FaTrain />}>
                    <p className={st.description}>
                    {renderTextWithLinks(selectedRouteInfo?.properties.description)}
                    </p>
                </TabPanel>
                <TabPanel header="Images" leftIcon="" disabled>
                    <p >Nothing</p>
                </TabPanel>
                <TabPanel header="Comments" leftIcon="" disabled>
                    <p >Nothing </p>
                </TabPanel>
            </TabView> */}

            <article className={st.description}>
              {renderTextWithLinks(selectedRouteInfo?.properties.description)}
            </article>
            {/* <article className={st.description}>{t("routes_images")}</article>
          <article className={st.description}>{t("routes_comments")}</article> */}

            <div className={st.downloadContainer}>
              <span className={st.downloadNote}>
                {t("routes_downloadExplained")}
              </span>
              <Button
                onClick={handleDownload}
                raised
                disabled={!selectedItinerary?.properties.altitudes}
              >
                <span className={st.downloadButton}>
                  {t("routes_download")}&nbsp;
                </span>
                <FaFileDownload size={23} />
              </Button>
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </article>
    </>
  );
};

export default Route;
