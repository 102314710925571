// NOTE: current to check state in reducer
import { createSlice, current } from "@reduxjs/toolkit";
import dayjs from "dayjs";

const TRAIN_ORIGIN_TITLE = "trainOrigin";
const BUS_ORIGIN_TITLE = "busOrigin";
const trainOriginLocalStorage = JSON.parse(
  localStorage.getItem(TRAIN_ORIGIN_TITLE)
);
const busOriginLocalStorage = JSON.parse(
  localStorage.getItem(BUS_ORIGIN_TITLE)
);

const initialState = {
  trainStops: [],
  ferries: [],
  ports: [],
  busStopsGeojson: { features: [], type: "FeatureCollection" },
  parkingGeojson: undefined,
  tripDate: dayjs().format("YYYY-MM-DD"),
  selectedMarker: undefined,
  trainOrigin: trainOriginLocalStorage,
  busOrigin: busOriginLocalStorage,
  timetables: { forward: [], return: [] },
};

export const transportSlice = createSlice({
  name: "transport",
  initialState,
  reducers: {
    addTrainStops: (state, action) => {
      state.trainStops = action.payload;
    },
    addFerriesPorts: (state, action) => {
      state.ferries = action.payload.ferries;
      state.ports = action.payload.ports;
    },
    addBusStops: (state, action) => {
      state.busStopsGeojson = action.payload;
    },
    addParking: (state, action) => {
      state.parkingGeojson = action.payload;
    },
    addSelectedMarker: (state, action) => {
      state.selectedMarker = action.payload;
    },
    addTripDate: (state, action) => {
      state.tripDate = action.payload;
    },
    addTrainOrigin: (state, action) => {
      state.trainOrigin = action.payload;
      localStorage.setItem(TRAIN_ORIGIN_TITLE, JSON.stringify(action.payload));
    },
    addBusOrigin: (state, action) => {
      state.busOrigin = action.payload;
      localStorage.setItem(BUS_ORIGIN_TITLE, JSON.stringify(action.payload));
    },
    addTrainTimetables: (state, action) => {
      state.timetables = action.payload;
    },
    addBusTimetables: (state, action) => {
      // console.log("state", current(state), action.payload);
      state.timetables = action.payload;
    },
  },
});

export const {
  addTrainStops,
  addFerriesPorts,
  addBusStops,
  addParking,
  addSelectedMarker,
  addTripDate,
  addTrainOrigin,
  addBusOrigin,
  addTrainTimetables,
  addBusTimetables,
} = transportSlice.actions;

export default transportSlice.reducer;
