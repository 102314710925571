import React, { memo } from "react";
import st from "./Map.module.css";
import { useTranslation } from "react-i18next";
import { FaTrain } from "react-icons/fa";
import { IoBus } from "react-icons/io5";
import { TbShip, TbParking } from "react-icons/tb";
import { GiRadarSweep } from "react-icons/gi";
import { MdOutlineCancel } from "react-icons/md";
const buttonOptions = {
  radar: {
    icon: GiRadarSweep,
    color: "var(--color-navigation)",
    size: 19,
    tooltip: "map_radar",
  },
  train: {
    icon: FaTrain,
    color: "var(--color-train)",
    size: 15,
    tooltip: "map_trains",
  },
  bus: {
    icon: IoBus,
    color: "var(--color-bus)",
    size: 19,
    tooltip: "map_buses",
  },
  ferry: {
    icon: TbShip,
    color: "var(--color-port)",
    size: 20,
    tooltip: "map_ferries",
  },
  parking: {
    icon: TbParking,
    color: "var(--color-parking)",
    size: 23,
    tooltip: "map_parking",
  },
  reset: {
    icon: MdOutlineCancel,
    color: "var(--color-train)",
    size: 23,
    tooltip: "map_reset",
  },
};

const CustomMapButton = ({
  type,
  IconComponent = buttonOptions[type].icon,
  isButtonToggle,
  setIsButtonToggle,
}) => {
  const { t } = useTranslation();
  
  return (
    <button
      className={[st.mapButton, type === "radar" && st.mobileError].join(" ")}
      onClick={() => setIsButtonToggle(!isButtonToggle)}
      disabled={type === "reset" && !isButtonToggle}
    >
      <IconComponent
        size={buttonOptions[type].size}
        color={isButtonToggle ? buttonOptions[type].color : "var(--color-black)"}
        style={{
          height: 29,
        }}
        title={t(buttonOptions[type].tooltip)}
      />
    </button>
  );
};

export default memo(CustomMapButton);
