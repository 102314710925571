import React, { useState, useEffect } from "react";
import API from "../api";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { feature, featureCollection, point } from "@turf/helpers";
import st from "./ItineraryLayout.module.css";
import Map from "../components/Map/Map";
import Weather from "../components/Weather/Weather";
import Elevation from "../components/Elevation/Elevation";
import {
  addRoutes,
  updateRoutes,
  addItinerary,
  selectItinerary,
} from "../redux/routeSlice";
import {
  addTrainStops,
  addFerriesPorts,
  addBusStops,
  addParking,
} from "../redux/transportSlice";
import { TRAIN, BUS, PARKING } from "../config/constants";
import Timetables from "../components/Timetables/Timetables";
import Sidebar from "../components/Sidebar/Sidebar";
import calculateDistances from "../helpers/calculateDistances";
import { initialState } from "../redux/routeSlice";
import distance from "@turf/distance";

const Itineraries = () => {
  const [isRouteSecondaryInfoOpen, setIsRouteSecondaryInfoOpen] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { trainStops, ports, busStopsGeojson, parkingGeojson, selectedMarker } =
    useSelector((state) => state.transportSlice);
  const { routesGeojson, selectedRouteInfo, itineraries, filters } =
    useSelector((state) => state.routeSlice);

  const isTimetable =
    selectedMarker?.properties.type === TRAIN ||
    selectedMarker?.properties.type === BUS;

  useEffect(() => {
    if (!routesGeojson?.features.length) {
      API.getRoutes(
        (fetchedRoutes) => {
          const routesGeojson = featureCollection(
            fetchedRoutes.reverse().map((route) =>
              feature(route.locationCoordinates, route)
            )
          );
          dispatch(addRoutes(routesGeojson));
        },
        (error) => console.error("Itinerary error:", error)
      );
    }

    if (!trainStops?.length) {
      API.getTrainStops(
        (fetchedTrainStops) => {
          dispatch(addTrainStops(fetchedTrainStops));
        },
        (error) => console.error("Itinerary train stops error:", error)
      );
    }

    if (!busStopsGeojson?.features.length) {
      API.getBusStations(
        (fetchedBusStops) => {
          const idsToExclude = [
            7210, // Forum Palace (Vilnius)
            6883, // Rock and Roll hotel
            5263, // Druskininkai Info-taškas
            7089, // Kauno ledo arena
            7209, // Akropolis (Klaipėda)
            7211, // Rumšiškės
            5653, // Vilnius (Fabijoniškės)
          ];
          const busStations = featureCollection(
            fetchedBusStops
              .filter((busStation) => !idsToExclude.includes(busStation.id))
              .map((busStation) =>
                point([busStation.longitude, busStation.latitude], {
                  title: busStation.title,
                  code: busStation.code,
                  id: busStation.id,
                  address: busStation.address,
                  type: BUS,
                })
              )
          );
          dispatch(addBusStops(busStations));
        },
        (error) => console.error("Itinerary bus stops error:", error)
      );
    }

    if (!ports.length) {
      API.getFerriesPorts(
        (fetchedFerriesPorts) => dispatch(addFerriesPorts(fetchedFerriesPorts)),
        (error) => console.error("Itinerary ports error:", error)
      );
    }

    if (!parkingGeojson) {
      API.getParking(
        (fetchedParking) => {
          const parkingData = featureCollection(
            fetchedParking.map((parking) =>
              feature(parking.geometry, {
                title: parking.title,
                isFree: parking.isFree,
                type: PARKING,
              })
            )
          );
          dispatch(addParking(parkingData));
        },
        (error) => console.error("Itinerary parkings error:", error)
      );
    }

    // Use setTimeout to ensure the code runs after the component has rendered and hides Maplibre attribution on start
    setTimeout(() => {
      const detailsElement = document.querySelector(
        ".maplibregl-ctrl.maplibregl-ctrl-attrib.maplibregl-compact-show"
      );
      if (detailsElement) {
        detailsElement.removeAttribute("open");
        detailsElement.classList.remove("maplibregl-compact-show");
      }
    }, 0);
  }, []);

  useEffect(() => {
    let fetchRoute;
    if (selectedRouteInfo) {
      const itineraryFind = itineraries.find(
        (geometry) => geometry.routeId === selectedRouteInfo.properties._id
      );
      if (itineraryFind) {
        dispatch(selectItinerary(itineraryFind));
      } else {
        setIsLoading(true);
        fetchRoute = setTimeout(() => {
          API.getRoute(
            (fetchedGeometry) => {
              const distances = calculateDistances(fetchedGeometry.geometry);
              const altitudes = fetchedGeometry.geometry.coordinates.map(
                (coord) => coord[2]
              );

              dispatch(
                addItinerary({
                  routeId: selectedRouteInfo.properties._id,
                  geometry: fetchedGeometry.geometry,
                  properties: {
                    distances: distances,
                    altitudes: altitudes,
                  },
                })
              );
              dispatch(
                selectItinerary({
                  routeId: selectedRouteInfo.properties._id,
                  geometry: fetchedGeometry.geometry,
                  properties: {
                    distances: distances,
                    altitudes: altitudes,
                  },
                })
              );
            },
            (error) => console.error("Selected Itinerary error:", error),
            selectedRouteInfo.properties._id
          );
          setIsLoading(false);
        }, "1000");
      }
      return () => clearTimeout(fetchRoute);
    } else {
      setIsLoading(false);
      dispatch(selectItinerary(null));
    }
  }, [selectedRouteInfo]);

  useEffect(() => {
    if (routesGeojson) {
      const {
        isJustRollerbladesFilter,
        difficultyFilter,
        distanceFilter,
        isRangeFilter,
        rangeFilter,
        homeLocation,
      } = filters;
      const isDistanceFilter =
        distanceFilter[0] === initialState.filters.distanceFilter[0] &&
        distanceFilter[1] === initialState.filters.distanceFilter[1];

      const isDifficultyFilter = difficultyFilter.some((value) => !value);

      if (
        !isRangeFilter &&
        isDistanceFilter &&
        !isJustRollerbladesFilter &&
        !isDifficultyFilter
      ) {
        dispatch(updateRoutes(routesGeojson));
      }

      const filteredRoutes = routesGeojson.features.filter((route) => {
        const passesDistanceFilter =
          isDistanceFilter ||
          (route.properties.distance >= distanceFilter[0] &&
            route.properties.distance <= distanceFilter[1]);

        const passesRangeFilter =
          !isRangeFilter ||
          distance(point(homeLocation), feature(route.geometry), {
            units: "kilometers",
          }) <= rangeFilter;

        const passesRollebladesFilter =
          !isJustRollerbladesFilter || route.properties.isSmooth;

        const passesDifficultyFilter =
          !isDifficultyFilter || difficultyFilter[route.properties.difficulty];

        return (
          passesDistanceFilter &&
          passesRangeFilter &&
          passesRollebladesFilter &&
          passesDifficultyFilter
        );
      });

      dispatch(updateRoutes(featureCollection(filteredRoutes)));
    }
  }, [filters]);

  return (
    <main className={st.container}>
      <article className={st.content}>
        <section className={st.routeMainInfo}>
          <Map />
          <Outlet
            context={[isRouteSecondaryInfoOpen, setIsRouteSecondaryInfoOpen]}
          />
        </section>
        <section
          className={[
            st.routeSecondaryInfo,
            isRouteSecondaryInfoOpen && st.routeSecondaryInfoActive,
          ].join(" ")}
        >
          <Elevation isLoading={isLoading} />
          <Weather />
        </section>
      </article>
      <Sidebar />
      {isTimetable && <Timetables />}
    </main>
  );
};

export default Itineraries;
