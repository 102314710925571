import { NavLink } from "react-router-dom";
import React, { useState } from "react";
import st from "./Header.module.css";
import { FaBars, FaBicycle } from "react-icons/fa";
import { VscChromeClose } from "react-icons/vsc";
import LanguageDropDown from "../LanguageDropDown/LanguageDropDown";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { IoMdLock } from "react-icons/io";

const Header = () => {
  const [toggleBtn, setToggleBtn] = useState(false);
  const handleClick = () => setToggleBtn(false);
  const handleClickToggle = () => setToggleBtn(!toggleBtn);
  const { t } = useTranslation();

  const activeStyle = [st.active, st.links].join(" ");
  const passiveStyle = st.links;
  const isRoutesList = useLocation().pathname === "/routes";

  return (
    <header className={st.header}>
      <div className={st.container}>
        <NavLink to="/" className={st.logo}>
          <FaBicycle style={{ fontSize: "1.7em", verticalAlign: "middle" }} />
          <span style={{ color: "var(--color-white)" }}>Day</span>
          <i>Ride</i>
        </NavLink>
        <nav
          className={
            toggleBtn ? [st.menu, st.mobileMenuActive].join(" ") : st.menu
          }
        >
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive ? activeStyle : passiveStyle
            }
            onClick={handleClick}
            end
          >
            {t("header_home")}
          </NavLink>
          <NavLink
            to="/about"
            className={({ isActive }) =>
              isActive ? activeStyle : passiveStyle
            }
            onClick={handleClick}
          >
            {t("header_about")}
          </NavLink>
          <NavLink
            to="/routes"
            className={({ isActive }) =>
              isActive && isRoutesList ? activeStyle : passiveStyle
            }
            onClick={handleClick}
          >
            {t("header_routes")}
          </NavLink>
          <NavLink
            to="/contact"
            className={({ isActive }) =>
              isActive ? activeStyle : passiveStyle
            }
            onClick={handleClick}
          >
            {t("header_contact")}
          </NavLink>
          {/* <NavLink
            to="/join"
            className={({ isActive }) =>
              isActive ? activeStyle : passiveStyle
            }
            onClick={handleClick}
          >
            {t("header_join")}
            <b style={{ color: "var(--color-secondary)" }}>!</b>
          </NavLink> */}
          <NavLink
            to="/contribute"
            className={({ isActive }) =>
              isActive ? activeStyle : passiveStyle
            }
            onClick={handleClick}
          >
            {t("header_contribute")}
            <b style={{ color: "var(--color-secondary)" }}>!</b>
          </NavLink>
          <NavLink
            to="/privacy"
            className={({ isActive }) =>
              isActive ? activeStyle : passiveStyle
            }
            onClick={handleClick}
          >
            <IoMdLock />
          </NavLink>
          <LanguageDropDown />
        </nav>
        <div className={st.icon} onClick={handleClickToggle}>
          {toggleBtn ? <VscChromeClose /> : <FaBars />}
        </div>
      </div>
    </header>
  );
};

export default Header;
