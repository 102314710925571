import { createSlice, current } from "@reduxjs/toolkit";
import dayjs from "dayjs";

export const initialState = {
  routesGeojson: { features: [], type: "FeatureCollection" },
  filteredRoutesGeojson: { features: [], type: "FeatureCollection" },
  selectedRouteInfo: null,
  weatherForecasts: [],
  itineraries: [],
  selectedItinerary: null,
  elevationIndex: null,
  filters: {
    difficultyFilter: [true, true, true, true],
    isJustRollerbladesFilter: false,
    distanceFilter: [0, 100],
    isRangeFilter: false,
    rangeFilter: 50,
    homeLocation: [23.94, 55.22],
  }
};

export const routeSlice = createSlice({
  name: "routeList",
  initialState,
  reducers: {
    addRoutes: (state, action) => {
      state.routesGeojson = action.payload;
      state.filteredRoutesGeojson = action.payload;
    },
    updateRoutes: (state, action) => {
      state.filteredRoutesGeojson = action.payload;
    },
    updateDistanceFilter: (state, action) => {
      state.filters.distanceFilter = action.payload;
    },
    setRangeFilter: (state) => {
      state.filters.isRangeFilter = !state.filters.isRangeFilter;
    },
    updateRangeFilter: (state, action) => {
      state.filters.rangeFilter = action.payload;
    },
    updateRangeFilterLocation: (state, action) => {
      state.filters.homeLocation = action.payload;
    },
    updateRollerbladesFilter: (state, action) => {
      state.filters.isJustRollerbladesFilter = action.payload;
    },
    updateDifficultyFilter: (state, action) => {
      // console.log("state", current(state), action.payload);
      state.filters.difficultyFilter = action.payload;
    },
    resetFilter: (state) => {
      state.filters = initialState.filters;
    },
    selectRoute: (state, action) => {
      state.selectedRouteInfo = state.filteredRoutesGeojson?.features.find(
        (route) => route.properties._id === action.payload
      );
    },
    removeSelectedRoute: (state) => {
      state.selectedRouteInfo = initialState.selectedRouteInfo;
      state.selectedItinerary = initialState.selectedItinerary;
    },
    addItinerary: (state, action) => {
      state.itineraries = [...state.itineraries, action.payload];
    },
    selectItinerary: (state, action) => {
      state.selectedItinerary = action.payload;
    },
    addWeather: (state, action) => {
      state.weatherForecasts = [
        ...state.weatherForecasts,
        {
          routeId: action.payload.routeId,
          weather: action.payload.forecast,
          updatedAt: dayjs().format(),
        },
      ];
    },
    updateWeather: (state, action) => {
      state.weatherForecasts = state.weatherForecasts?.map((weather) => {
        if (weather.routeId !== action.payload.routeId) {
          return weather;
        }
        return {
          ...weather,
          ...{
            weather: action.payload.forecast,
            updatedAt: dayjs().format(),
          },
        };
      });
    },
    addElevationIndex: (state, action) => {
      state.elevationIndex = action.payload;
    },
  },
});

export const {
  addRoutes,
  updateRoutes,
  updateDistanceFilter,
  setRangeFilter,
  updateRangeFilter,
  updateRangeFilterLocation,
  updateRollerbladesFilter,
  updateDifficultyFilter,
  resetFilter,
  selectRoute,
  removeSelectedRoute,
  addItinerary,
  selectItinerary,
  addWeather,
  updateWeather,
  addElevationIndex
} = routeSlice.actions;

export default routeSlice.reducer;
