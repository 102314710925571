import React from "react";
import st from "./Sidebar.module.css";
import { useTranslation } from "react-i18next";

const Sidebar = () => {
  const { t } = useTranslation();

  return (
    <aside className={st.container}>
      <h4 className={st.h4}>{t("sidebar_title")}</h4>
    </aside>
  );
};

export default Sidebar;
