import React, { useState } from "react";
import st from "./PublicTransportInfo.module.css";
import { useTranslation } from "react-i18next";
import { IoBus } from "react-icons/io5";
import { FaTrain } from "react-icons/fa";
import { GrCircleQuestion } from "react-icons/gr";
import { Dialog } from "primereact/dialog";

const PublicTransportInfo = () => {
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <button
        className={st.publicTransportInfoButton}
        onClick={() => setIsDialogVisible(true)}
      >
        <GrCircleQuestion
          size={25}
          color="var(--color-secondary)"
          title={t("publicTransportInfo_title")}
        />
      </button>
      <Dialog
        header={t("publicTransportInfo_title")}
        visible={isDialogVisible}
        onHide={() => setIsDialogVisible(false)}
        draggable={false}
        className={st.dialog}
      >
        <article>
          <ul className={st.publicTransportInfo}>
            <li>
              <span className={st.icon}>
                <FaTrain size={35} color="red" />
              </span>
              <span>{t("publicTransportInfo_train")}</span>
            </li>
            <li>
              <span className={st.icon}>
                <IoBus size={35} color="green" />
              </span>
              <span>{t("publicTransportInfo_bus")}</span>
            </li>
          </ul>
        </article>
      </Dialog>
    </>
  );
};

export default PublicTransportInfo;
