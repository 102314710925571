import React, { useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import st from "./CookieBanner.module.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import ReactGA4 from "react-ga4";
import { useLocation } from "react-router-dom";

const CookieBanner = () => {
  const { t } = useTranslation();
  const toast = useRef(null);
  const location = useLocation();
  const GA_ID = process.env.REACT_APP_GA_TRACKING_ID;

  const hideCookieBanner = () => {
    toast.current.clear();
    ReactGA4.initialize(GA_ID);
    ReactGA4.send({ hitType: "pageview", page: window.location.pathname });
  };

  const showCookieBanner = () => {
    toast.current.clear();
    toast.current.show({
      severity: "success",
      sticky: true,
      closable: false,
      content: () => (
        <div className={st.container}>
          <div className={st.toastTitle}>{t("privacy_banner_title")}</div>
          <div className={st.toastContent}>{t("privacy_banner_content")}</div>
          <div className={st.toastBottom}>
            <Link to="/privacy" className={st.link}>
              {t("privacy_title")}
            </Link>
            <Button
              label={t("privacy_banner_button")}
              severity="success"
              onClick={hideCookieBanner}
              size="small"
            ></Button>
          </div>
        </div>
      ),
    });
  };

  useEffect(() => {
    const userConsent = Cookies.get("_ga");
    if (!userConsent && GA_ID) {
      showCookieBanner();
    }
  }, [location]);

  return <Toast ref={toast} position="bottom-right" className={st.toast} />;
};

export default CookieBanner;
