import { getDistance } from "geolib";
// NOTE: change to @turf/distance?

const calculateDistances = (geometry) => {
  let prevDistance = 0;

  const result = geometry.coordinates.slice(1).map((coord, index) => {
    const prevCoord = geometry.coordinates[index];
    const singleDistance = getDistance(
      {
        latitude: prevCoord[1],
        longitude: prevCoord[0],
      },
      {
        latitude: coord[1],
        longitude: coord[0],
      }
    );

    prevDistance += singleDistance;
    return Math.round(prevDistance / 100) / 10;
  });

  return [0, ...result];
};

export default calculateDistances;