import React from "react";
import st from "./RouteCard.module.css";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { selectRoute, removeSelectedRoute } from "../../redux/routeSlice";
import { TbArrowWaveRightUp, TbRollerSkating, TbShip } from "react-icons/tb";
import { IoBus } from "react-icons/io5";
import { GiCrossroad, GiMountainRoad, GiHorizonRoad } from "react-icons/gi";
import { FaTrain } from "react-icons/fa";
import { FaRoad } from "react-icons/fa6";
import { Link, generatePath } from "react-router-dom";
import { routeDifficulty } from "../../config/constants";
import { IconCard, IconActiveCard } from "../CustomIcon/CustomIcon";

const RouteCard = ({ route }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <li
      className={st.routeCardContainer}
      onMouseEnter={() => dispatch(selectRoute(route._id))}
      onMouseLeave={() => dispatch(removeSelectedRoute())}
    >
      <Link
        className={st.routeCard}
        to={generatePath("/routes/:id", { id: route._id })}
      >
        <div className={st.routeCardLine}>
          <h4 className={st.h4}>{route.title}</h4>
          <div className={st.betweenIcons}>
            <TbArrowWaveRightUp
              className={st.icon}
              color={routeDifficulty[route.difficulty]}
              title={t("routes_card_difficulty")}
            />
            <IconActiveCard
              CustomIcon={TbRollerSkating}
              title={t("routes_card_isSmooth")}
              isActive={route.isSmooth}
            />
          </div>
        </div>

        <div className={st.routeCardLine}>
          <div className={st.iconCard}>
            <span className={st.distance}>{route.distance}</span>
            <small>km</small>
          </div>
          <div className={st.betweenIcons}>
            <IconCard
              CustomIcon={FaRoad}
              title={t("routes_card_asphalt")}
              distance={route.asphaltRoad}
            />
            <IconCard
              CustomIcon={GiHorizonRoad}
              title={t("routes_card_gravel")}
              distance={route.gravelRoad}
            />
            <IconCard
              CustomIcon={GiMountainRoad}
              title={t("routes_card_offroad")}
              distance={route.offRoad}
            />
            <IconCard
              CustomIcon={GiCrossroad}
              title={t("routes_card_bikepath")}
              distance={route.bikePath}
            />
          </div>
          <div className={st.betweenIcons}>
            <IconActiveCard
              CustomIcon={TbShip}
              title={t("routes_card_ferry")}
              isActive={route.isFerry}
            />
            <IconActiveCard
              CustomIcon={IoBus}
              title={t("routes_card_bus")}
              isActive={route.isBus}
            />
            <IconActiveCard
              CustomIcon={FaTrain}
              title={t("routes_card_train")}
              isActive={route.isTrain}
            />
          </div>
        </div>
      </Link>
    </li>
  );
};

export default RouteCard;
